export const dealerListObjects = [
  {
    dealerName: "Jaipur Cycle Hub",
    location: "https://maps.google.com/maps?cid=10885972574225172188",
    pincode: "302039",
    address:
      "GROUND FLOOR , PLOT NUMBER B-32VIVEKANAND COLONY , NAYA KHEDA , AMBABARI",
    latitude: "26.94940511",
    longitude: "75.77769293",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-jaipur/cycle-shop-in-ambabari/emotorad-electric-cycles-jaipur-cycle-hub-in-ambabari-jaipur--0792b509-bbf5-4867-95e9-ebeb24375315/home",
  },
  {
    dealerName: "THE CYCLE WORLD - Indore",
    location: "https://www.google.com/maps?cid=14023007410892444250",
    pincode: "452010",
    address: "14, TAWAS COMPLEX,BITTEN MARKET, BHOPAL",
    latitude: "22.6595076707981",
    longitude: "75.82408923103222",
    WebpageURL: "",
  },
  {
    dealerName: "M/S RAJESH CYCLE TRADING CO",
    location: "https://maps.google.com/maps?cid=16046672803156762116",
    pincode: "201301",
    address:
      "H-2, SECTOR-9, Near Harola Vegetable Market, NOIDA, Gautam Buddha Nagar, Uttar Pradesh",
    latitude: "28.58633264",
    longitude: "77.32450197",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-noida/cycle-shop-in-sector-18/emotorad-experience-store-rajesh-cycles-in-sector-18-noida--8e1c4f25-ae03-4f65-9c86-cdd22930d7c2/home",
  },
  {
    dealerName: "JUST BICYCLE AND ACCESSORIES",
    location: "https://www.google.com/maps?cid=5397829783722988953",
    pincode: "110055",
    address: "27,DDA Cycle Market,Jhandewalan,Extn,Central Delhi",
    latitude: "28.6460754",
    longitude: "77.203209",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-new-delhi/cycle-shop-in-jhandewalan/emotorad-experience-store-in-jhandewalan-new-delhi--039ec4f9-174c-49ba-ae34-d386612ecfc6/home",
  },
  {
    dealerName: "Avery Freewheel pvt Ltd",
    location: "https://www.google.com/maps?cid=4099126160800088064",
    pincode: "160002",
    address: "Plot no. 23, industrial area ,phase 1, chandigarh",
    latitude: "30.71093007",
    longitude: "76.8080365",
    WebpageURL: "",
  },
  {
    dealerName: "DARSHAN CYCLE",
    location: "https://maps.google.com/maps?cid=10490603827801311814",
    pincode: "160036",
    address: "SHOWROOM NO.401,SECTOR-37D",
    latitude: "30.74102592",
    longitude: "76.74799963",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-chandigarh/cycle-shop-in-sector-37/emotorad-electric-cycles-darshan-cycle-in-sector-37-chandigarh--9fb96d6b-c6c5-4560-a5ac-121343a9f503/home",
  },
  {
    dealerName: "Bodke Cycles",
    location: "https://maps.google.com/maps?cid=17272068891790080553",
    pincode: "411033",
    address:
      "Ground Floor, Sr. No. 7/2/4, Jai Malhar Nagar, Kalewadi Main Road, Thergaon, Pune",
    latitude: "18.52502995",
    longitude: "73.85093256",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-pune/cycle-shop-in-shivajinagar/emotorad-electric-cycles-bodke-cycle-in-shivajinagar-pune--0dc7dfbf-76ae-4aa2-a587-ffba7177b546/home",
  },
  {
    dealerName: "THE CYCLE JUNCTION",
    location: "https://maps.google.com/maps?cid=10954424238415146382",
    pincode: "380054",
    address: "GF/16, Goyal Terrace, JUDGES BUNGLOW, Bodakdev, Ahmedabad",
    latitude: "23.03732801",
    longitude: "72.51583544",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-ahmedabad/cycle-shop-in-bodakdev/emotorad-electric-cycles-the-cycle-junction-in-bodakdev-ahmedabad--74ffb940-f3a2-4746-a539-ffea890966f2/home",
  },
  {
    dealerName: "ZEMS CYCLES",
    location: "https://maps.google.com/maps?cid=1752429944251029861",
    pincode: "411038",
    address: "Anand Complex Paud Road , Kothrud  Near",
    latitude: "18.50719841",
    longitude: "73.80667249",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-pune/cycle-shop-in-kothrud/emotorad-electric-cycles-zems-cycles-in-kothrud-pune--81acf666-ee8e-4cd9-8f10-61afe0a87c9c/home",
  },
  {
    dealerName: "Surana Cycles- Pune",
    location: "https://maps.google.com/maps?cid=4786133181147599228",
    pincode: "411002",
    address: "743, Budhwar Peth, Pune",
    latitude: "18.51893136",
    longitude: "73.85894566",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-pune/cycle-shop-in-budhwar-peth/emotorad-electric-cycles-surana-cycles-in-budhwar-peth-pune--81598c61-ce0f-4200-9c9b-35a0033756d0/home",
  },
  {
    dealerName: "Wheel Sports",
    location: "https://www.google.com/maps?cid=11587317119644264360",
    pincode: "412207",
    address:
      "Shop No. 1, Near Crystal Honda Showroom, Next to Yamaha Showroom, New Bhadale Wasti, Wagholi, Pune",
    latitude: "18.59675918",
    longitude: "73.89787559",
    WebpageURL: "",
  },
  {
    dealerName: "Jalaram House Of Cycles",
    location: "https://maps.google.com/maps?cid=12055476896261946490",
    pincode: "400104",
    address: "26,Hirnen shopping center,opp ratna hotel,S.V road, Goregaon",
    latitude: "19.16344262",
    longitude: "72.84565168",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-mumbai/cycle-shop-in-goregaon-west/emotorad-electric-cycles-jalaram-house-of-cycles-in-goregaon-west-mumbai--8e134ee1-1a9e-4a07-b600-2ab79747ff2a/home",
  },
  {
    dealerName: "Royal Cycle",
    location: "https://www.google.com/maps?cid=12817804425949553268",
    pincode: "411043",
    address: "S/NO-30/3/1/6, DHANKAWADI, PUNE SATARA ROAD, PUNE",
    latitude: "18.46464771",
    longitude: "73.85785904",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-pune/cycle-shop-in-dhankawadi/emotorad-electric-cycles-royal-cycle-in-dhankawadi-pune--ffca9f18-d369-44fe-9ff1-4bbd42cefce6/home",
  },
  {
    dealerName: "MEGHRAJ EMPORIUM",
    location: "https://maps.google.com/maps?cid=5073795376952514578",
    pincode: "400601",
    address:
      "GROUND FLOOR, SHOP NO 1, RAMCHANDRA BHAVAN, UTHALSAR NAKA, UTHALSAR",
    latitude: "19.20325056",
    longitude: "72.97841217",
    WebpageURL: "",
  },
  {
    dealerName: "ASHAPURACYCLE MART",
    location: "https://maps.google.com/maps?cid=9690986518672751801",
    pincode: "411014",
    address:
      "S. No. 54/1, Near Railway Over Bridge, Mundhwa-Magarpatta Rd, Kirtane Baug, Mundhwa",
    latitude: "18.55889738",
    longitude: "73.9315128",
    WebpageURL: "",
  },
  {
    dealerName: "THE SHELAR CYCLES",
    location: "https://www.google.com/maps?cid=11642529474568229997",
    pincode: "411009",
    address: "178,Parvati Gaon, Parvati Paytha,Pune",
    latitude: "18.49718184",
    longitude: "73.85090897",
    WebpageURL: "",
  },
  {
    dealerName: "Rise Up Cycles",
    location: "https://maps.google.com/maps?cid=5560913113856422257",
    pincode: "421204",
    address:
      "Shop No16 , Arihant Aarohi Complex , Phase2 , Padale Village , NearPalava City, Dombivili Thane",
    latitude: "19.15155521",
    longitude: "73.05675417",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-thane/cycle-shop-in-padale-village/emotorad-electric-cycles-rise-up-in-padale-village-thane--0004d14a-633a-4d50-8b46-37607e8fb041/home",
  },
  {
    dealerName: "MODERN E BIKES",
    location: "https://maps.app.goo.gl/XW8XhYod11wfRi2MA",
    pincode: "411058",
    address:
      "FLAT NO.23, ACHARYA SOCIETY, S.NO.16/2, WARJE NAKA, Warje Malwadi",
    latitude: "18.48358226",
    longitude: "73.8087745",
    WebpageURL: "",
  },
  {
    dealerName: "JANATA ENTERPRISES",
    location: "https://maps.google.com/maps?cid=14482010378001296335",
    pincode: "411007",
    address: "Shop 4, Bhakti plaza, Opposite Aundh Police chowky, Aundh, Pune",
    latitude: "18.56247881",
    longitude: "73.81393516",
    WebpageURL: "",
  },
  {
    dealerName: "MODERN CYCLE COMPANY",
    location: "https://maps.google.com/maps?cid=6300591946577126223",
    pincode: "411033",
    address: "SR NO 2/12, HONRAO CHAWL, CHINCHWAD, PUNE",
    latitude: "18.64251119",
    longitude: "73.79251357",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-pimpri-chinchwad/cycle-shop-in-dattaraj-colony/emotorad-electric-cycles-bodke-in-dattaraj-colony-pimpri-chinchwad--08fcff6d-0dcb-4844-8a39-4b34ff0f59f9/home",
  },
  {
    dealerName: "SURANA SALES",
    location: "https://maps.google.com/maps?cid=10780459554316299668",
    pincode: "411011",
    address:
      "SHOP NO 346 347, SHRAM SAPHALYA BUILDING, 16 KASBA PETH GANESH ROAD",
    latitude: "18.51902754",
    longitude: "73.85756897",
    WebpageURL: "",
  },
  {
    dealerName: "Metro Enterprises",
    location: "https://maps.google.com/maps?cid=14831886552844962073",
    pincode: "401201",
    address:
      "Shop No 5 & 6 King Anthony Building Opposite Stella Petrol Pump Vasai west",
    latitude: "19.3580762",
    longitude: "72.79960601",
    WebpageURL: "",
  },
  {
    dealerName: "Bhagyalaxmi Group",
    location: "https://www.google.com/maps?cid=8613494004951121231",
    pincode: "411034",
    address:
      "Nashik Phata, Survey No.415, Pune Nshik Road, Kasarwadi, Pune, Maharashtra",
    latitude: "18.60888129",
    longitude: "73.82248479",
    WebpageURL: "",
  },
  {
    dealerName: "CYCLE ZONE",
    location: "https://maps.app.goo.gl/UhufSoSZiN8bzQxS9",
    pincode: "360001",
    address: "GROUND FLOOR,1St.fLOOR To 3Rd.Floor, CYCLE ZONE, VRUNDAVAN PARK",
    latitude: "22.27766833",
    longitude: "70.76210488",
    WebpageURL: "",
  },
  {
    dealerName: "MOHAN CYCLE WORKS",
    location: "https://maps.google.com/maps?cid=374828735910426756",
    pincode: "380008",
    address: "24, SINDHI MARKET, MANINAGAR, MANINAGAR, Ahmedabad ,Gujarat",
    latitude: "22.9999584",
    longitude: "72.61029491",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-ahmedabad/cycle-shop-in-maninagar/emotorad-electric-cycles-mohan-cycle-works-in-maninagar-ahmedabad--f92d70a3-25f7-4d02-8ec3-9ae1b375f17b/home",
  },
  {
    dealerName: "THE CYCLEWALA-Thane",
    location: "https://www.google.com/maps?cid=3739974804490298714",
    pincode: "400607",
    address:
      "Shop No-21 D Wing, S No. 195 To 197, Near Brahmand Phase - VII, Opp- Shreeji Splender",
    latitude: "19.25108597",
    longitude: "72.98585921",
    WebpageURL: "",
  },
  {
    dealerName: "Adarsh Sales Corporation",
    location: "https://www.google.com/maps?cid=9988887455158707377",
    pincode: "411013",
    address:
      "sadalaxmi complex,Shop 10-11, beside Bank of Maharashtra,before, Reliance mart, Fatimanagar",
    latitude: "18.50164208",
    longitude: "73.90078226",
    WebpageURL: "",
  },
  {
    dealerName: "Amardeep Cycles",
    location: "https://maps.google.com/maps?cid=1235367955896723633",
    pincode: "400028",
    address: "Navprabhat Chambers, Ranade Road, Dadar West, Mumbai Suburban",
    latitude: "19.02125925",
    longitude: "72.83968766",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-mumbai/cycle-shop-in-dadar-west/emotorad-electric-cycles-amardeep-cycles-in-dadar-west-mumbai--7ab885fe-01db-4ec2-a11b-c7c0a7ce0b61/home",
  },
  {
    dealerName: "PEDAL POWER INTERNATIONAL / PEOPLE'S SQUARE",
    location: "https://maps.google.com/maps?cid=1986573712751387592",
    pincode: "410206",
    address:
      "Plot no- 151 shop no B 1 to 5, Patil complex M.C.C.H society, Panvel",
    latitude: "18.98774833",
    longitude: "73.11347467",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-raigarh/cycle-shop-in-panvel/emotorad-electric-cycles-pedal-power-international-in-panvel-raigarh--52ead141-f3f9-45a8-83c0-174f089f053f/home",
  },
  {
    dealerName: "SHREE KRISHNA CYCLE",
    location: "https://maps.app.goo.gl/ZckzeXPkLwmmJDZt5",
    pincode: "410501",
    address: "SHOP 3195/1, CHAKAN, PUNE",
    latitude: "18.74795331",
    longitude: "73.85957784",
    WebpageURL: "",
  },
  {
    dealerName: "Surendar Cycle Store Pune",
    location: "https://maps.google.com/maps?cid=11786961422872359302",
    pincode: "411004",
    address: "40/25, Bhonde colony, opp Sonal hall, Karve Rd pune",
    latitude: "18.51113655",
    longitude: "73.83562074",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-pune/cycle-shop-in-bhonde-colony/emotorad-electric-cycle-surendar-cycle-in-bhonde-colony-pune--ec0eb3b2-7f54-4c5c-92a1-f723ebc2e1e1/home",
  },
  {
    dealerName: "UP Cycle",
    location: "https://maps.google.com/maps?cid=15017910259358785414",
    pincode: "700069",
    address:
      "Shop No 66, UP Cycles, Bentinck St, near Faltu Tea Centre, Mission Row Extension, Esplanade, Chowringhee North, Bow Barracks, Kolkata, West Bengal 700069",
    latitude: "22.56854583",
    longitude: "88.35285574",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-kolkata/cycle-shop-in-chowringhee-north/emotorad-electric-cycles-u-p-cycle-in-chowringhee-north-kolkata--2296646b-3949-4b54-8989-a3fa84697031/home",
  },
  {
    dealerName: "Fancy Cycle",
    location: "https://maps.google.com/maps?cid=9541193601140566075",
    pincode: "751009",
    address:
      "in front of Water Tank Lane, Bapuji Nagar, Bhubaneswar, Odisha 751009",
    latitude: "20.26077364",
    longitude: "85.83728204",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-bhubaneswar/cycle-shop-in-bapuji-nagar/emotorad-electric-cycles-fancy-cycle-in-bapuji-nagar-bhubaneswar--2cb9bd34-d531-488a-b4b2-6900be18ceb3/home",
  },
  {
    dealerName: "Berhampur Cycle",
    location: "https://maps.google.com/maps?cid=12188700494532314370",
    pincode: "760002",
    address:
      "Siddharth Clinic Rd, Sano Bazar, Triveni Nagar, Brahmapur, Odisha 760002",
    latitude: "19.3159317",
    longitude: "84.78189743",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-ganjam/cycle-shop-in-berhampur/emotorad-electric-cycles-ms-berhampur-cycles-in-berhampur-ganjam--a53ae7d5-c30b-4a21-9ba2-a1da3ef68e28/home",
  },
  {
    dealerName: "Mahabir Cycle Store",
    location: "https://www.google.com/maps?cid=1718738647134977418",
    pincode: "756003",
    address:
      "Town Hall Rd, near Cinema Square, Manikhamb, Balasore, Gopalgoan, Odisha 756001",
    latitude: "21.49384904",
    longitude: "86.93751664",
    WebpageURL: "",
  },
  {
    dealerName: "BHARATH CYCLE HUB AND CENTER",
    location: "https://maps.google.com/maps?cid=4251545773819923019",
    pincode: "560064",
    address:
      "1143, New Town Main Rd, MIG Sector B, Yelahanka Satellite Town, Yelahanka New Town, Bengaluru",
    latitude: "13.09473505",
    longitude: "77.58083564",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-bengaluru/cycle-shop-in-yelahanka-new-town/emotorad-electric-cycles-bharath-cycle-in-yelahanka-new-town-bengaluru--44e16b9d-eccc-4742-8e1a-e33f4a15b5e4/home",
  },
  {
    dealerName: "BIKE INNOVATIONS",
    location: "https://maps.app.goo.gl/asc6cv5RZNHJb9nG7",
    pincode: "560079",
    address:
      "Anjanadri Arcade, No 1, Basaveshwara Nagar, L.I.C. Colony, 1st Cross",
    latitude: "12.99757013",
    longitude: "77.53863656",
    WebpageURL: "",
  },
  {
    dealerName: "COSMOS SPORTS WORLD LIMITED LIABILITY PARTNERSHIP",
    location: "https://www.google.com/maps?cid=2022161273896041073",
    pincode: "673004",
    address:
      "Puthiya Purayil, Elathur, Kozhikode, Kozhikode, Elathur, Kerala, 673303, India",
    latitude: "11.25271884",
    longitude: "75.79559146",
    WebpageURL: "",
  },
  {
    dealerName: "Exautomotive Tech",
    location: "https://maps.google.com/maps?cid=2120250441258003760",
    pincode: "560067",
    address: "The ebike store, G3, CKR complex, Seegehalli, Kadugodi",
    latitude: "13.01291238",
    longitude: "77.7624642",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-bengaluru/cycle-shop-in-belatur-colony/emotorad-electric-cycles-exautomotive-tech-in-belatur-colony-bengaluru--00746628-30d9-4dd6-9e1f-79977b18ae9b/home",
  },
  {
    dealerName: "JAYANT CYCLE STORES",
    location: "https://www.google.com/maps?cid=1546624522045435627",
    pincode: "560011",
    address:
      "Just Buy Cycles, Shop No A-101, 3rd Avenue, Anna Arch Rd, near Aminjikarai Police Station, Anna Nagar, Chennai, Tamil Nadu 600102",
    latitude: "12.91560183",
    longitude: "77.64499327",
    WebpageURL: "",
  },
  {
    dealerName: "JUST BUY CYCLES",
    location: "https://maps.google.com/maps?cid=11756210079469842116",
    pincode: "600102",
    address:
      "Shop No 1, Jayant Pro Bikes, Plot No 18, nearby Bodycraft Salon, nearby Muthahalli Veg Restaurant, Vanganahalli, 1st Sector, HSR Layout, Bengaluru, Karnataka 560100",
    latitude: "13.08172936",
    longitude: "80.21806626",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-chennai/cycle-shop-in-anna-nagar-east/emotorad-electric-cycles-just-buy-cycles-in-anna-nagar-east-chennai--32b490f1-2220-40b3-a33f-ab4612065111/home",
  },
  {
    dealerName: "PEDALLERS POINT",
    location: "https://www.google.com/maps?cid=11359604191212255507",
    pincode: "500032",
    address:
      "1-58//1, Ground Floor, 1st Floor, 2nd Floor, Plot No.1 Survey No.225, Opp. Mytri Nagar Kaman,",
    latitude: "17.49513325",
    longitude: "78.34767557",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-hyderabad/cycle-shop-in-rajiv-gandhi-nagar/emotorad-electric-cycles-pedallers-point-in-rajiv-gandhi-nagar-hyderabad--851ae7c1-4023-464d-9c66-961fc31b3ed9/home",
  },
  {
    dealerName: "PRUDHVIS CYCLING STUDIO",
    location: "https://www.google.com/maps?cid=6192151691316248318",
    pincode: "500081",
    address:
      "Shop-2,Uptown Cyberabad Building,100 Feet Road,near YSR Statue,Madhapur",
    latitude: "17.45291956",
    longitude: "78.38543387",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-hyderabad/cycle-shop-in-madhapur/emotorad-experience-store-prudhvis-cycling-studio-in-madhapur-hyderabad--36d09df5-9a10-42f5-ac33-ec09b232136f/home",
  },
  {
    dealerName: "SRI TIRUMALA SAI CYCLE PALACE",
    location: "https://maps.app.goo.gl/zrWmDvrqcbDCzpnZA",
    pincode: "500060",
    address:
      "13-23/1075, Metro P. No 1586 ,NETHAJI NAGAR, GADDIANARAM SAROORNAGAR, HYDERABAD",
    latitude: "17.36811359",
    longitude: "78.5374295",
    WebpageURL: "",
  },
  {
    dealerName: "VELOCITY- THE BICYCLE SHOP",
    location: "https://maps.app.goo.gl/d56Uf85rekSZENwC7",
    pincode: "500014",
    address:
      "3-66, Ground floor, P Mohan Reddy Commercial Complex, Runway 9 building , beside DCB bank,",
    latitude: "17.5353538",
    longitude: "78.48914619",
    WebpageURL:
      "https://dealers.emotorad.com/emotorad/cycle-shop-in-hyderabad/cycle-shop-in-kompally/emotorad-electric-cycles-velocity-in-kompally-hyderabad--b898d9d2-11ef-4bf6-881f-b7c14b1004b7/home",
  },
  {
    dealerName: "GUPTA CYCLE STORE-CHANDIGARH",
    location: "",
    pincode: "",
    address: "SCO NO. - 71, SECTOR- 31C",
    latitude: "",
    longitude: "",
    WebpageURL: "",
  },
  {
    dealerName: "VICTOR FLYER CYCLE MALL",
    location: "",
    pincode: "411041",
    address:
      "SURVEY NO.145, DHAYARI GARMAL, NEAR TRANSFORMER, SINHGAD ROAD, DHAYARI",
    latitude: "",
    longitude: "",
    WebpageURL: "",
  },
  {
    dealerName: "Porwal Cycles",
    location: "",
    pincode: "411045",
    address:
      "Showroom No.03,Deron Heights,Sr No.270/1/23, Baner Road Opp to Mahabaleshwar Hotel,Pune",
    latitude: "",
    longitude: "",
    WebpageURL: "",
  },
  {
    dealerName: "ACE KUDALE CORPORATION LIMITED",
    location: "",
    pincode: "411030",
    address:
      "133/D2 VIJAYA LAXMAN BLDG PARVATI, DARSHAN SOC, NEAR SANE GURUJI ROAD OFF, SINHGAD ROAD",
    latitude: "",
    longitude: "",
    WebpageURL: "",
  },
  {
    dealerName: "Chitra Enterprises",
    location: "",
    pincode: "827004",
    address: "",
    latitude: "",
    longitude: "",
    WebpageURL: "",
  },
];
