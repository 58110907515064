import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const BharatOnEMotoradHome = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [blogPosts, setBlogPosts] = useState([]);
  const hygraph = new GraphQLClient(
    "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
  );

  const QUERY = gql`
    {
      posts(orderBy: date_DESC) {
        id
        title
        slug
        tags
        date
        excerpt
        blogCategory
        coverImage {
          url
        }
        content {
          html
        }
      }
    }
  `;
  useEffect(() => {
    const getBlogs = async () => {
      const { posts } = await hygraph.request(QUERY);
      setBlogPosts(posts);
    };
    getBlogs();
  }, []);
  return (
    <>
      <Navbar breadcrumb={true} />
      <div className="mb-5">
        <h1 className="metropolis-bold text-center mt-4 mt-lg-5">
          <i>Bharat on EMotorad</i>
        </h1>
        <div
          className="d-flex align-items-center flex-column flex-lg-row nowrap blog-div container py-lg-5 py-4"
          style={{ gap: "2em" }}
        >
          <div>
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/blogs/Intro+Post.jpg"
              className="img-fluid"
              alt="Blog Cover"
            />
          </div>

          <div className="col-12 col-md-4">
            <p className="">
              Just like life, we navigate different roads on our journey. At
              EMotorad, we provide the means to help you reach your destination,
              no matter which path you choose. We achieve this through our
              products and people, inspired and fueled by your stories.
            </p>
            <p>
              Here are the gems of Bharat we’ve discovered along the way –
              individuals who rose above the ordinary and showed us what it
              means to be truly limitless.
            </p>
            <p className="fw-bold mb-0">
              Uncovering Bharat on EMotorad<br></br>Inspiring stories on two
              pedals across India
            </p>
          </div>
        </div>
      </div>
      <div className="container my-lg-5 my-4">
        <div className="">
          <div className="row">
            {blogPosts &&
              blogPosts
                .filter((post) => post.blogCategory === "bharatOnEMotorad")
                // .slice(
                //   currentCategory === "latestUpdates" ? 1 : 0,
                //   currentCategory === "latestUpdates" ? 7 : blogPosts.length
                // )
                .map((post, i) => (
                  <div key={i} className="col-md-4 col-12 mb-4 blog-div">
                    <Link
                      to={`/bharat-on-emotorad/${post.slug}`}
                      className="text-decoration-none text-dark"
                    >
                      <div
                        className=" rounded-3  h-100"
                        style={{ boxShadow: "1px 1px 5px lightgrey" }}
                      >
                        <div>
                          <img
                            src={post?.coverImage?.url}
                            alt=""
                            className="img-fluid rounded-2"
                          />
                        </div>
                        <div className="p-3">
                          <div className="d-flex justify-content-between mb-3">
                            {post.blogCategory && (
                              <p
                                style={{ fontSize: "0.9em" }}
                                className="text-em m-0 blog-category fw-bold"
                              >
                                {"Bharat on EMotorad"}
                              </p>
                            )}
                            <p
                              style={{ fontSize: "0.9em" }}
                              className="text-muted m-0"
                            >
                              <span className="pe-2 text-muted">
                                {months[new Date(post.date).getMonth()]} {""}
                                {new Date(post.date).getDate()},{" "}
                                {new Date(post.date).getFullYear()}
                              </span>
                            </p>
                          </div>

                          <div className="">
                            <h5
                              className="mb-4 blog-heading"
                              style={{
                                fontFamily: "Metropolis-medium",
                                display: "-webkit-box",
                                maxWidth: "100%",
                                fontSize: "1.15em",
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {post.title}
                            </h5>
                            <p
                              className="text-muted mt-2"
                              style={{
                                display: "-webkit-box",
                                fontSize: "0.9em",
                                maxWidth: "100%",
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {post.excerpt}
                            </p>
                          </div>
                        </div>

                        {/* <Link
                                to={`/blogs/${post.slug}`}
                                style={{}}
                                className="background-em w-100 text-decoration-none d-block text-center border-0 rounded-3 text-light p-2 w-100"
                              >
                                Read More{" "}
                                <Icon icon="ph:arrow-right-light" width={30} />
                              </Link> */}
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BharatOnEMotoradHome;
