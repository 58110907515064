import React, { useEffect, useState } from "react";
import bluelogo from "../images/EM-LOGO_Blue.png";
import "../CSS/ecycleLandingPage.css";
import { dealerListObjects } from "../Components/dealerList";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  EffectCoverflow,
  Scrollbar,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import "@splidejs/splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ECycleLandingPage = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dealerName, setDealerName] = useState("");
  const [pincode, setPincode] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [dealerList, setDealerList] = useState([]);
  const [id, setId] = useState("");
  const [gclid, setGclid] = useState("");

  useEffect(() => {
    // Fetching UTM campaign parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const GClid = urlParams.get("gclid");
    // Set the gclid in the state
    if (GClid) {
      setGclid(GClid);
    }
  }, []);

  useEffect(() => {
    if (pincode.length === 6) {
      axios
        .post(`api/oms/pincode`, { pincode })
        .then((response) => {
          setCity(response.data.data.district.district_name);
          console.log(response.data.data.district.district_name);
        })
        .catch((error) => {
          setCity("");
        });
    }
  }, [pincode]);

  const pincodeSubmit = async (e) => {
    e.preventDefault();
    function haversineDistance(lat1, lon1, lat2, lon2) {
      const toRad = (angle) => (Math.PI / 180) * angle;
      const R = 6371; // Radius of Earth in km

      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // Distance in km
    }

    async function getLatLongFromPincode(pincode) {
      const apiKey = "AIzaSyBIaH1D753G5bOfcm7kExBfP7uwOQoqHYo"; // Replace with your key
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=${apiKey}`
      );
      const data = await response.json();

      if (data.status !== "OK" || data.results.length === 0) return null;

      const location = data.results[0].geometry.location;
      return { lat: location.lat, lon: location.lng };
    }

    async function findLocationsInRadius(pincode, locationsArray) {
      const pincodeLocation = await getLatLongFromPincode(pincode);
      if (!pincodeLocation) {
        console.log("Invalid pincode or location not found.");
        return [];
      }

      const { lat, lon } = pincodeLocation;
      return locationsArray.filter((location) => {
        const distance = haversineDistance(
          lat,
          lon,
          parseFloat(location.latitude),
          parseFloat(location.longitude)
        );
        return distance <= 20;
      });
    }
    // Usage
    async function handleDealerSearch() {
      const result = await findLocationsInRadius(pincode, dealerListObjects);
      setDealerList(result);
      if (result.length) {
        setShowMessage(
          "Thank you! Your details have been successfully submitted. Please find your nearest dealer below."
        );
      } else {
        setShowMessage("Don't worry! Our team will get back to you.");
      }
      try {
        const response = await axios.post("/api/dealer/ecycle-lead", {
          name,
          phoneNumber,
          dealerName,
          city,
          pincode,
          location,
          gclid,
        });

        if (response) {
          setName("");
          setPhoneNumber("");
          setPincode("");
          setId(response.data.data._id);
        }
      } catch (error) {
        console.error("Error submitting dealer lead:", error);
      }
    }

    // Call the function
    handleDealerSearch();
  };

  useEffect(() => {
    const submitForm = async () => {
      try {
        const response = await axios.post("/api/dealer/ecycle-lead", {
          name,
          phoneNumber,
          dealerName,
          pincode,
          location,
          gclid,
        });
        if (response) {
          setId(response.data.data._id);
          setName("");
          setPhoneNumber("");
          setPincode("");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
  }, [name, phoneNumber, pincode]);

  const dealerSubmit = (dealer, locationUrl, WebpageURL) => async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`/api/dealer/ecycle-lead/${id}`, {
        dealerName: dealer,
        location: WebpageURL ? WebpageURL : locationUrl,
      });
      if (response) {
        setId(response.data.data._id);
        setName("");
        setPhoneNumber("");
        setPincode("");
      }
      if (WebpageURL) {
        window.open(WebpageURL, "_blank");
      } else {
        window.open(locationUrl, "_blank");
      }
    } catch (error) {}
  };

  SwiperCore.use([
    Navigation,
    Pagination,
    EffectCoverflow,
    Scrollbar,
    Autoplay,
  ]);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div>
      <div
        className="position-sticky navigation w-100"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="m-0 p-0 p-3 ps-lg-5 pe-lg-5 desktop-nav">
          <div className="d-flex justify-content-center">
            <img
              src={bluelogo}
              className="img-fluid"
              style={{ width: "130px" }}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="hero-banner position-relative mb-lg-5">
        <picture className="main-banner-img">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/mobileBanner.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/desktopBanner.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/desktopBanner.jpg"
            alt="t-Rex+banner"
            className="d-block w-100"
          />
        </picture>
        <div className="position-absolute form-placement-ecycle-page">
          <div className="form-placement-inner-div px-3 px-lg-0">
            <div className="p-3 form-div-ecycle-page">
              <h5 className="mb-3 text-center">
                Visit your{" "}
                <span className="metropolis-medium">nearest Dealership</span>
              </h5>
              <form className="" onSubmit={pincodeSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control rounded-1"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex" style={{ gap: "0.5em" }}>
                  <div className="mb-3 w-50">
                    <input
                      type="tel"
                      className="form-control rounded-1"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => {
                        const phoneNumber = e.target.value.slice(0, 12);
                        setPhoneNumber(phoneNumber);
                        if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                          e.target.setCustomValidity("");
                        } else {
                          e.target.setCustomValidity(
                            "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                          );
                        }
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                        )
                      }
                      required
                    />
                  </div>
                  <div className="mb-3 w-50">
                    <input
                      type="tel"
                      className="form-control rounded-1"
                      placeholder="Pin Code"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-2">
                  <button
                    className="btn em-btn rounded-1 py-2 w-100"
                    style={{ fontSize: "0.8em" }}
                  >
                    Submit
                  </button>
                </div>
                {showMessage && (
                  <p
                    style={{ fontSize: "0.75em", color: "#00FF7F" }}
                    className="text-center m-0 metropolis-medium"
                  >
                    {showMessage}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {dealerList.length ? (
        <div className="my-5 px-lg-5 px-3">
          <h4 className="text-center text-uppercase metropolis-medium mb-lg-4 mb-3 d-none d-lg-block">
            Your nearby EMotorad Store
          </h4>
          <h5 className="text-center text-uppercase metropolis-medium mb-lg-4 mb-3 d-lg-none">
            Your nearby EMotorad Store
          </h5>
          <Swiper
            spaceBetween={17}
            slidesPerView={1.3}
            initialSlide={0}
            centeredSlides={true}
            loop={true}
            modules={[Pagination, Navigation]}
            pagination={{ clickable: true }}
            simulateTouch={true}
            slide
            speed={1000}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: true,
              },
            }}
          >
            {dealerList.map((dealer, item) => {
              return (
                <SwiperSlide className="pb-5">
                  <div
                    className="rounded-2 p-lg-4 p-4"
                    style={{ backgroundColor: "#F9F9F9" }}
                  >
                    <div className="">
                      <h5
                        className="metropolis-medium store-name-size store-name-height"
                        style={{ textTransform: "capitalize" }}
                      >
                        {dealer.dealerName}
                      </h5>
                      <div className="mb-3 store-address-height">
                        <small className="store-address-size">
                          {dealer.address}
                        </small>
                      </div>
                      <button
                        className="directions-link"
                        onClick={dealerSubmit(
                          dealer.dealerName,
                          dealer.location,
                          dealer.WebpageURL
                        )}
                        style={{ border: "none", background: "transparent" }}
                      >
                        <i className="fa-solid fa-diamond-turn-right"></i>{" "}
                        <b>Directions</b>
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <></>
      )}
      <div className="benefits-section-ecycle-page py-lg-4 pt-4">
        <h1 className="mb-4 dfg text-uppercase text-center px-lg-5 px-3 py-2 d-lg-none metropolis-medium">
          One{" "}
          <Link
            to={"/"}
            style={{ cursor: "none", color: "inherit" }}
            className="text-decoration-none"
          >
            E-Cycle
          </Link>
          , Endless Possibilities
        </h1>
        <div className="d-flex align-items-center" style={{ gap: "4em" }}>
          <div className="d-lg-block d-none" style={{ width: "40%" }}>
            <h1 className="mb-4 text-uppercase metropolis-medium ps-lg-5 ms-5">
              One{" "}
              <Link
                to={"/"}
                style={{ cursor: "auto", color: "inherit" }}
                className="text-decoration-none"
              >
                E-Cycle
              </Link>
              , Endless Possibilities
            </h1>
            <div className="content py-4 ps-lg-5 ms-5">
              {activeSlide === 0 && (
                <>
                  <h2 className="text-em metropolis-medium">
                    Cruise Through Traffic
                  </h2>
                  <h5 className="text-muted">Pedal less, go further</h5>
                </>
              )}

              {activeSlide === 1 && (
                <>
                  <h2 className="text-em metropolis-medium mb-2">
                    Travel up to 60 km per charge
                  </h2>
                  <h5 className="text-muted mb-0">Commute Sweat Free </h5>
                </>
              )}

              {activeSlide === 2 && (
                <>
                  <h2 className="text-em metropolis-medium mb-2">
                    25 km/h Speed
                  </h2>
                  <h5 className="text-muted mb-0">
                    Throttle to powerful acceleration
                  </h5>
                </>
              )}

              {activeSlide === 3 && (
                <>
                  <h2 className="text-em metropolis-medium mb-2">
                    0 Fuel Cost
                  </h2>
                  <h5 className="text-muted mb-0">Just ₹0.07 per km</h5>
                </>
              )}
            </div>
          </div>
          <div className="d-lg-block d-none" style={{ width: "60%" }}>
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              spaceBetween={10}
              slidesPerView={2.5}
              initialSlide={0}
              centeredSlides={true}
              onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
              loop={true}
              slide
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <div className="text-center pb-4">
                  <img
                    className="img-fluid mb-3 rounded-3"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Cruise+Through+Traffic.jpg"
                  ></img>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center pb-4">
                  <img
                    className="img-fluid mb-3 rounded-3"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Commute+More.jpg"
                  ></img>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center pb-4">
                  <img
                    className="img-fluid mb-3 rounded-3"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Ride+2x+Faster.jpg"
                  ></img>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center pb-4">
                  <img
                    className="img-fluid mb-3 rounded-3"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/No+Fuel+Cost.jpg"
                  ></img>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="d-lg-none">
          <Swiper
            spaceBetween={17}
            slidesPerView={1.5}
            initialSlide={0}
            centeredSlides={true}
            loop={true}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            slide
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <div className="text-center pb-5">
                <img
                  className="img-fluid mb-3 rounded-3"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Cruise+Through+Traffic.jpg"
                ></img>
                <h5 className="text-em metropolis-medium mb-1">
                  Cruise Through Traffic
                </h5>
                <p className="text-muted mb-0">Pedal less, go further</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center pb-5">
                <img
                  className="img-fluid mb-3 rounded-3"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Commute+More.jpg"
                ></img>
                <h5 className="text-em metropolis-medium mb-1">
                  Travel up to 60 km per charge
                </h5>
                <p className="text-muted mb-0">Commute Sweat Free</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center pb-5">
                <img
                  className="img-fluid mb-3 rounded-3"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Ride+2x+Faster.jpg"
                ></img>
                <h5 className="text-em metropolis-medium mb-1">
                  25 km/h Speed
                </h5>
                <p className="text-muted mb-0">
                  Throttle to powerful acceleration
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center pb-5">
                <img
                  className="img-fluid mb-3 rounded-3"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/No+Fuel+Cost.jpg"
                ></img>
                <h5 className="text-em metropolis-medium mb-1">0 Fuel Cost</h5>
                <p className="text-muted mb-0"> Just ₹0.07 per km</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className=" w-100 mt-3 mt-lg-5">
        <div className="ps-lg-5 pe-lg-5 ps-3">
          <h1
            className="text-center mb-3 text-uppercase heading-homepage"
            style={{
              fontFamily: "Metropolis-medium",
            }}
          >
            smart features, smart rides
          </h1>
        </div>

        <div className="row m-0">
          <div className="col-lg-8 col-12 p-0 mb-lg-0 mb-2">
            <div className="position-relative">
              {window.innerWidth <= 650 ? (
                <video
                  autoPlay
                  muted
                  loop
                  disableRemotePlayback
                  playsInline
                  className="w-100"
                >
                  <source
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Desktop(Battery).mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(Battery).gif"
                  alt="Banner1"
                  className="w-100"
                />
              )}
              <div
                style={{
                  position: "absolute",
                  top: "0%",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  background: "linear-gradient(to bottom, transparent, black)",
                }}
              />
              <div className="position-absolute desk-gif-1">
                <h2
                  className="text-uppercase text-light m-0 mobile-gif-heading"
                  style={{
                    fontFamily: "Metropolis-bolder",
                    fontWeight: "900",
                  }}
                >
                  Charge anywhere
                </h2>
                <p className="text-light m-0 mb-2 gif-content">
                  Like your phone, plug it in, and zip-zap-zoom!
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-12 p-0 ps-lg-3">
            <div className="d-flex d-lg-block " style={{ gap: "0.5em" }}>
              <div className="position-relative mb-0 mb-lg-3">
                {window.innerWidth <= 650 ? (
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="w-100"
                  >
                    <source
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Mobile(Motor).mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <LazyLoadImage
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(Motor).gif"
                    alt="Banner1"
                    className="w-100"
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom, transparent, black)",
                  }}
                />
                <div className="position-absolute desk-gif-2 pe-2">
                  <h3
                    className="text-uppercase text-light m-0 mobile-gif-heading"
                    style={{
                      fontFamily: "Metropolis-bolder",
                      fontWeight: "900",
                    }}
                  >
                    SWEAT BY CHOICE
                  </h3>
                  <p className="text-light m-0 mb-2 gif-content d-none d-lg-block">
                    Pedal or accelerate, either way, we got your back
                  </p>
                  <p className="text-light m-0 mb-2 gif-content d-lg-none">
                    Pedal or accelerate, we got you
                  </p>
                </div>
              </div>
              <div className="position-relative">
                <picture>
                  <source
                    media="(max-width:650px)"
                    srcSet={
                      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/display-gif-mobile.gif"
                    }
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet={
                      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(display).gif"
                    }
                  />
                  <LazyLoadImage
                    src={
                      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(display).gif"
                    }
                    alt="Banner1"
                    className="w-100"
                  />
                </picture>
                <div
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom, transparent, black)",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom, transparent, black)",
                  }}
                />
                <div className="position-absolute desk-gif-3 pe-2">
                  <h3
                    className="text-uppercase text-light m-0 mobile-gif-heading"
                    style={{
                      fontFamily: "Metropolis-bolder",
                      fontWeight: "900",
                    }}
                  >
                    Lifetime Warranty*
                  </h3>
                  <p className="text-light m-0 mb-2 gif-content d-lg-block d-none">
                    Rest assured we'll take care of you, or your bike at least!
                  </p>
                  <p className="text-light m-0 mb-2 gif-content d-lg-none">
                    We will take care of you
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="brand-usp-ecycle-page pt-lg-5 pt-4 px-lg-5 px-3 my-3">
        <h3 className="text-center mb-0 metropolis-medium text-uppercase">
          What makes us the best?
        </h3>
      </div>
      <Swiper
        spaceBetween={17}
        slidesPerView={1.3}
        initialSlide={0}
        centeredSlides={true}
        loop={true}
        modules={[Pagination, EffectCoverflow, Autoplay]}
        pagination={{ clickable: true }}
        slide
        speed={1000}
        cverflowEffect={{
          rotate: 0,
          stretch: -85,
          depth: 1050,
          scale: 1.1,
          modifier: 1.6,
          slideShadows: false,
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 20,
            centeredSlides: true,
          },
        }}
      >
        <SwiperSlide className="pb-5">
          <img
            className="img-fluid rounded-3"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/No+1+bestseller+amazon+flipkart.jpg"
          ></img>
        </SwiperSlide>
        <SwiperSlide className="pb-5">
          <img
            className="img-fluid rounded-3"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Removable+Battery.jpg"
          ></img>
        </SwiperSlide>
        <SwiperSlide className="pb-5">
          <img
            className="img-fluid rounded-3"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/Upto+lifetime+component+warranty.jpg"
          ></img>
        </SwiperSlide>
        <SwiperSlide className="pb-5">
          <img
            className="img-fluid rounded-3"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/72+hour+quick+resolution.jpg"
          ></img>
        </SwiperSlide>
        <SwiperSlide className="pb-5">
          <img
            className="img-fluid rounded-3"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/500%2B+dealerships+pan+india.jpg"
          ></img>
        </SwiperSlide>
        <SwiperSlide className="pb-5">
          <img
            className="img-fluid rounded-3"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/dealer+ecycle+lead/1+Lakh%2B+Happy+Customers.jpg"
          ></img>
        </SwiperSlide>
      </Swiper>
      <div
        className=" position-relative mt-lg-5 mt-4 pb-lg-4"
        style={{
          minHeight: "400px",
        }}
      >
        <div
          className="position-relative"
          style={{
            backgroundImage:
              'url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/ABout%2BUs.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "350px",
          }}
        >
          <div
            className="position-absolute h-100 w-100"
            style={{ background: "rgba(0,0,0,0.6)" }}
          ></div>
          <h3
            className="position-absolute text-light w-100 text-center"
            style={{ fontFamily: "Metropolis-bolder", top: "30%" }}
          >
            “MOST PROMISING EV OF THE YEAR”{" "}
          </h3>{" "}
          <p
            className="text-light mt-1 w-100 text-center celebs-heading text-uppercase"
            style={{ fontSize: "0.9em" }}
          >
            That’s what they said
          </p>
        </div>
        <div className="ps-3" style={{ marginTop: "-5em" }}>
          <Swiper
            spaceBetween={5}
            slidesPerView={1.3}
            autoplay={true}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 6.2,
                spaceBetween: 20,
              },
            }}
            slide
          >
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Bhaichung.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Sonali.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Isha.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Jayant.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Nitin.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>{" "}
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Shaan.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>{" "}
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Suniel.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>{" "}
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Terence.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>{" "}
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Neha+Dhupia.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>{" "}
            <SwiperSlide className="p-1 rounded-3">
              <LazyLoadImage
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Angad+Bedi.jpg"
                alt=""
                className="img-fluid"
              />
            </SwiperSlide>{" "}
          </Swiper>
        </div>
      </div>
      <div className="px-lg-5 px-3 my-lg-5 my-4">
        <h3
          className="text-uppercase mb-4 text-center"
          style={{ fontFamily: "Metropolis-medium" }}
        >
          Our Customers Are Loving it!
        </h3>
        <Swiper
          navigation={{ clickable: true }}
          spaceBetween={15}
          slidesPerView={1.3}
          initialSlide={2}
          centeredSlides={false}
          loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
              centeredSlides: false,
              navigation: { clickable: false },
              arrows: false,
              initialSlide: 4,
              allowTouchMove: false,
              loop: false,
              slide: false,
              noSwiping: true,
            },
          }}
          slide
        >
          <SwiperSlide className="">
            <div className="">
              <div>
                <img
                  className="w-100"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  alt="reviews"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-1.jpg"
                ></img>
              </div>
              <div
                className="p-3 review-1-background"
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <h5 style={{}} className="mb-1 text-light">
                  Karan Joshi
                </h5>
                <div>
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#review1Modal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="">
            <div className="">
              <div>
                <img
                  className="w-100"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  alt="reviews"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-2.jpg"
                ></img>
              </div>
              <div
                className="p-3 review-2-background"
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <h5 style={{}} className="mb-1 text-light">
                  Kalpana Jain
                </h5>
                <div>
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#review2Modal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="">
            <div className="">
              <div>
                <img
                  className="w-100"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  alt="reviews"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-3.jpg"
                ></img>
              </div>
              <div
                className="p-3 review-3-background"
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <h5 style={{}} className="mb-1 text-light">
                  Sukhveer Singh
                </h5>
                <div>
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#review3Modal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="">
            <div className="">
              <div>
                <img
                  className="w-100"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  alt="reviews"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-4.jpg"
                ></img>
              </div>
              <div
                className="p-3 review-4-background"
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <h5 style={{}} className="mb-1 text-light">
                  Sanskar Maroo
                </h5>
                <div>
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                  <Icon
                    icon="ic:round-star-rate"
                    style={{ color: "#ffce31" }}
                    width={"20"}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#review4Modal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* review 1 modal */}
        <div
          class="modal fade"
          id="review1Modal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  I recently purchased the Emotorad X1 electric cycle, and it
                  has completely exceeded my expectations. From the sleek design
                  to the powerful performance, this e-bike is a game-changer.
                  The build quality is exceptional, providing a sturdy and
                  reliable feel while riding. The battery life is impressive,
                  allowing for extended rides without worrying about running out
                  of power. The pedal-assist feature is seamless, making uphill
                  climbs a breeze. The electric motor delivers a smooth and
                  responsive ride, and the overall handling is superb. Whether
                  you're a seasoned cyclist or new to electric bikes, the
                  Emotorad X1 offers a fantastic riding experience for all. In
                  conclusion, I highly recommend the Emotorad X1 to anyone
                  looking for a high-quality electric cycle in affordable price.
                  It combines style, performance, and innovation seamlessly,
                  making it a standout choice in the market.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* review 2 modal */}
        <div
          class="modal fade"
          id="review2Modal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  I recently purchased the EMotorad Doodle V2 e-cycle, and I am
                  thrilled with my experience! The bike's sleek design, coupled
                  with its powerful electric motor, provides a smooth and
                  enjoyable ride. The battery life exceeded my expectations,
                  allowing for longer rides without worrying about running out
                  of power. The intuitive controls and comfortable seating make
                  it a joy to navigate through various terrains. EMotorad has
                  truly created a fantastic electric cycle that combines style,
                  functionality, and efficiency. I highly recommend the Doodle
                  V2 to anyone looking for a reliable and fun e-cycle.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* review3 modal */}
        <div
          class="modal fade"
          id="review3Modal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  The E MOTORAD X2 is a remarkable electric bike that combines
                  impressive performance with exceptional comfort and
                  convenience. With its powerful motor and long-lasting battery,
                  the X2 delivers a smooth and efficient ride, making it perfect
                  for both daily commutes and weekend adventures. The bike's
                  sturdy frame and high-quality components ensure durability and
                  reliability, while its ergonomic design provides a comfortable
                  riding experience even on longer journeys. The X2's compact
                  size and lightweight construction make it easy to manoeuvre in
                  urban environments, while its foldable design allows for
                  convenient storage and transportation. Overall, the E MOTORAD
                  X2 is a fantastic electric bike that offers excellent value
                  for money, making it a top choice for anyone looking for a
                  reliable and enjoyable mode of transportation.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* review 4 modal */}
        <div
          class="modal fade"
          id="review4Modal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  I recently purchased an e-cycle, and I must say, it has
                  completely transformed my commuting experience. This electric
                  bike combines the convenience of a traditional bicycle with
                  the efficiency of an electric motor, offering a truly
                  versatile mode of transportation.First and foremost, the
                  e-cycle is incredibly eco-friendly. By opting for an electric
                  bike over a traditional vehicle, I'm significantly reducing my
                  carbon footprint and contributing to a cleaner environment.
                  It's empowering to know that my daily commute is now
                  emissions-free.One of the standout features of the e-cycle is
                  its electric motor. With just a simple push of a button, I can
                  effortlessly glide up hills and cover long distances without
                  breaking a sweat. The motor provides a welcome boost,
                  especially during those morning commutes when I'm not quite
                  awake yet.Moreover, the e-cycle is surprisingly versatile.
                  Whether I'm navigating busy city streets or exploring scenic
                  bike paths, it handles beautifully in all environments. The
                  sturdy frame and responsive brakes instill confidence, while
                  the adjustable settings allow me to customize my riding
                  experience to suit my preferences.In terms of convenience, the
                  e-cycle truly shines. It's equipped with a removable battery
                  that can be easily charged at home or the office, ensuring
                  that I always have enough power for my journeys. Plus, the
                  sleek design and compact size make it easy to store in tight
                  spaces, whether it's in my apartment or a crowded bike
                  rack.Overall, I couldn't be happier with my e-cycle purchase.
                  Not only has it revolutionized my daily commute, but it's also
                  helped me lead a more sustainable lifestyle. If you're in the
                  market for an efficient and eco-friendly mode of
                  transportation, I highly recommend considering an e-cycle.
                  It's truly a game-changer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="lifestyle-end"
        style={{ position: "relative" }}
        className="pt-lg-4 pt-3"
      >
        <picture style={{ height: "50%" }}>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/landing+page+banner+phone.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/landing+page+banner.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/landing+page+banner.jpg"
            className="img-fluid w-100 lifestyle-image"
            alt=""
            srcSet=""
          />
        </picture>
        <div className="position-absolute call-icon d-none d-lg-block">
          <a href="https://api.whatsapp.com/send/?phone=918686050590&text=Hi,%20I%20am%20interested%20to%20know%20more%20about%20emotorad%20cycles&app_absent=0">
            <Icon icon="logos:whatsapp-icon" width="2.7em" height="2.7em" />
          </a>
        </div>
        <div className="position-absolute whatsapp-icon d-none d-lg-block">
          <div
            className="rounded-50"
            style={{
              backgroundColor: "#004dac",
              borderRadius: "50%",
              height: "2.7rem",
              width: "2.7rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a href="tel:+918686050590 " style={{ textDecoration: "none" }}>
              <Icon
                icon="ion:call"
                width="1.5em"
                height="1.5em"
                style={{ color: "#ffffff" }}
              />
            </a>
          </div>
        </div>
        <div className="position-absolute call-icon d-lg-none">
          <a href="https://api.whatsapp.com/send/?phone=918686050590&text=Hi,%20I%20am%20interested%20to%20know%20more%20about%20emotorad%20cycles&app_absent=0">
            <Icon icon="logos:whatsapp-icon" width="1.8em" height="1.8em" />
          </a>
        </div>
        <div className="position-absolute whatsapp-icon d-lg-none">
          <div
            className="rounded-50"
            style={{
              backgroundColor: "#004dac",
              borderRadius: "50%",
              height: "1.8rem",
              width: "1.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a href="tel:+918686050590 " style={{ textDecoration: "none" }}>
              <Icon
                icon="ion:call"
                width="1em"
                height="1em"
                style={{ color: "#ffffff" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECycleLandingPage;
