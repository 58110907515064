import React, { useEffect, useRef, useState } from "react";
import Footer from "../Components/Footer";
import Meta from "../Components/Meta";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/home.css";
import "../CSS/mobHome.css";
import { getAllBikes } from "../Actions/bikeActions";
import SwiperCore, { Pagination, Autoplay, EffectCoverflow } from "swiper";
import Navbar from "../Components/Navbar";
import CallBtn from "../Components/CallBtn";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import TestRide from "../Components/TestRide";
import "swiper/swiper.min.css";
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the CSS for blur effect
import "@splidejs/splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import LazyLoad from "react-lazy-load";
import { Helmet } from "react-helmet";
import WelcomePopup from "../Components/WelcomePopup";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const ytRef = useRef();
  const ytThumbnailRef = useRef();
  SwiperCore.use([Pagination, Autoplay, EffectCoverflow]);

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  const [modelStates, setModelStates] = useState(
    bikes ? Array(bikes.length).fill(0) : []
  );
  const handleColorClick = (bikeIndex, colorIndex) => {
    setModelStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[bikeIndex] = colorIndex;
      return newStates;
    });
  };

  useEffect(() => {
    if (!bikes) {
      dispatch(getAllBikes());
    }
  }, []);

  useEffect(() => {
    if (bikes) {
      setModelStates(Array(bikes.length).fill(0));
    }
  }, [bikes]);

  const currencyFormat = (num) => {
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  if (
    ytRef.current &&
    ytThumbnailRef.current &&
    ytThumbnailRef.current.splide
  ) {
    ytRef.current.sync(ytThumbnailRef.current.splide);
    ytThumbnailRef.current.sync(ytRef.current.splide);
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [activeItem, setActiveItem] = useState(1);
  const [activeSwiper, setActiveSwiper] = useState(4);
  const rangeData = [
    {
      id: 1,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/product+images/yellow/X1+Yellow+img+1.png",
      url2: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/product+images/yellow/X1+Yellow+img+3.png",
      name: "x-factor",
      description:
        "Adding the extra edge to your daily travels, The X-Factor range of e-bikes prove to be adaptable and affordable rides suitable for everyone, beginner or pro.",
    },
    {
      id: 2,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/product+images/neon/Emx+Plus+img+1.png",
      url2: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/product+images/neon/Emx+Plus+img+3.png",
      name: "desire",
      description:
        "Adding the extra edge to your daily travels, The Desire range of e-bikes prove to be adaptable and affordable rides suitable for everyone, beginner or pro.",
    },
  ];
  const handleHover = (id) => {
    if (activeItem === id) {
      return;
    }
    setActiveItem(id);
  };
  const handleHoverLeave = () => {
    if (activeItem === 1) {
      return;
    }
    setActiveItem(1);
  };
  const handleSlideChange = (swiper) => {
    setActiveSwiper(swiper.activeIndex);
  };
  const [isQuestionOne, setIsQuestionOne] = useState(false);
  const [isQuestionTwo, setIsQuestionTwo] = useState(false);
  const [isQuestionThree, setIsQuestionThree] = useState(false);
  const [isQuestionFour, setIsQuestionFour] = useState(false);
  const [isQuestionFive, setIsQuestionFive] = useState(false);
  const [isQuestionSix, setIsQuestionSix] = useState(false);
  const [isQuestionSeven, setIsQuestionSeven] = useState(false);
  const [isQuestionEight, setIsQuestionEight] = useState(false);

  const [isQuestionNine, setIsQuestionNine] = useState(false);

  const toggleQuestionOneCollapse = () => {
    setIsQuestionOne(!isQuestionOne);
  };
  const toggleQuestionTwoCollapse = () => {
    setIsQuestionTwo(!isQuestionTwo);
  };
  const toggleQuestionThreeCollapse = () => {
    setIsQuestionThree(!isQuestionThree);
  };
  const toggleQuestionFourCollapse = () => {
    setIsQuestionFour(!isQuestionFour);
  };
  const toggleQuestionFiveCollapse = () => {
    setIsQuestionFive(!isQuestionFive);
  };
  const toggleQuestionSixCollapse = () => {
    setIsQuestionSix(!isQuestionSix);
  };
  const toggleQuestionSevenCollapse = () => {
    setIsQuestionSeven(!isQuestionSeven);
  };
  const toggleQuestionEightCollapse = () => {
    setIsQuestionEight(!isQuestionEight);
  };
  const toggleQuestionNineCollapse = () => {
    setIsQuestionNine(!isQuestionNine);
  };

  const refVideo = useRef(null);

  const src =
    window.innerWidth > 651
      ? "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Trex+Pro.mp4"
      : "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Website+banner-T-Rex+Pro.mp4"; // Hardcoded video source
  const isMuted = false; // Hardcoded muted status

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }

    if (isMuted) {
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
    }

    refVideo.current.src = src;
  }, [src]);
  const [Collapse, setCollapse] = useState(false);

  useEffect(() => {
    const createProductListSchema = () => {
      if (bikes) {
        const productListSchema = {
          "@context": "http://schema.org/",
          "@type": "ItemList",
          itemListElement: bikes
            .filter(
              (bike) =>
                bike.name !== "EMX Aqua" &&
                bike.name !== "T-Rex+" &&
                bike.name !== "EMX" &&
                bike.name !== "EMX+" &&
                bike.name !== "Doodle V2" &&
                bike.name !== "X1 Limited Edition" &&
                bike.name !== "Neo"
            )
            .map((bike, index) => ({
              "@type": "ListItem",
              position: index + 1,
              url: `https://www.emotorad.com/bikes/${bike.urlName}`,
              image: bike.cartImages[0],
            })),
        };
        // console.log(productListSchema);
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(productListSchema);

        document.head.appendChild(script);
      }
    };
    createProductListSchema();
  }, [bikes]);

  return (
    <>
      {" "}
      {/* <WelcomePopup /> */}
      <Helmet>
        {/* Website Schema */}
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "EMotorad",
          "url": "https://www.emotorad.com"
        }
      `}
        </script>

        {/* Organization Schema */}
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "EMotorad",
          "url": "https://www.emotorad.com",
          "logo": "https://www.emotorad.com/static/media/EM-LOGO_Blue.ce684f6a98cc820b06d6.png",
          "description": "EMotorad offers a diverse range of premium electric bicycles and e-bikes. Shop now for the best deals on e-cycle & e-bike.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "At post Jambe",
            "addressLocality": "Taluka Mulshi",
            "addressRegion": "159/2 Sangawade Pune",
            "postalCode": "411033",
            "addressCountry": "India"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": " +918686050590",
            "contactType": "customer service"
          },
          "founders": [
            {
              "@type": "Person",
              "name": "Rajib Gangopadhyay"
            },
            {
              "@type": "Person",
              "name": "Kunal Gupta"
            },
            {
              "@type": "Person",
              "name": "Aditya Oza"
            },
            {
              "@type": "Person",
              "name": "Sumedh Battewar"
            }
          ],
          "sameAs": [
            "https://www.facebook.com/EMotorad",
            "https://www.instagram.com/e_motorad/",
            "https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg",
            "https://twitter.com/e_motorad",
            "https://www.linkedin.com/company/emotorad/"
          ]
        }
      `}
        </script>
      </Helmet>
      <Meta
        title={"Electric Cycle Online, Buy Best E Cycle & E-Bike | EMotorad"}
        description={
          "EMotorad offers a diverse range of premium electric bicycles and e-bikes. Shop now for the best deals on e-cycle & e-bike."
        }
        cannonicalTag={"https://www.emotorad.com/"}
      />
      <>
        <Navbar />
        <CallBtn bottom={"20%"} />
        {/* <WhatsAppBot bottom={"12%"} /> */}
        <TestRide />
        <div className="position-relative w-100" style={{ margin: "auto" }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modal-video"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                zIndex: "100",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                position: "static",
                margin: "auto",
                border: "none",
                padding: "0px",
                outline: "none",
                overflow: "hidden",
              },
            }}
            contentLabel="Video Modal"
          >
            <ReactPlayer
              url="https://youtu.be/hHlB3ikhAMo?si=vV7Mc8VMoXE6neFN"
              controls={true}
              playing={true}
              width="100%"
              height="100%"
            />
          </Modal>
        </div>

        {/* hero banner */}
        <div className="blogs-card pt-0 ps-0 pe-0">
          <Splide
            className=" p-0 align-items-center"
            options={{
              arrows: true,
              drag: true,
              type: "loop",
              pagination: true,
              autoplay: true,
              perMove: 1,
              gap: "0em",
              padding: "0em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 4000,
              perPage: 1,
              speed: 1000,
              breakpoints: {
                700: {
                  perPage: 1,
                  arrows: false,
                  pagination: true,
                  autoplay: false,
                  perMove: 1,
                  interval: 4000,
                  gap: "0em",
                  padding: "0em",
                  pauseOnHover: false,
                  resetProgress: false,
                  focus: 0,
                  start: 0,
                  speed: 500,
                },
              },
            }}
            muted={true}
          >
            <SplideSlide>
              <div
                className="position-relative"
                onClick={openModal}
                style={{ cursor: "pointer" }}
              >
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Website+phone+dhoni2.0.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/web+banner+dhoni2.0.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/web+banner+dhoni2.0.jpg"
                    alt="Banner1"
                    className="d-block w-100"
                  />
                </picture>
                <div
                  className="play-icon-container"
                  onClick={openModal}
                  style={{ cursor: "pointer" }}
                >
                  {window.innerWidth > 700 ? (
                    <Icon icon="logos:youtube-icon" width={60} />
                  ) : (
                    <Icon icon="logos:youtube-icon" width={50} />
                  )}
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <Link to={"/bikes/xcape-g1"} className="text-decoration-none">
                  <picture className="main-banner-img">
                    <source
                      media="(max-width:650px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/cargo+mobile.jpg"
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/cargo+desktop.jpg"
                    />
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/cargo+desktop.jpg"
                      alt="Banner1"
                      className="d-block w-100"
                    />
                  </picture>
                </Link>

                <div className="position-absolute xcape-g1-button-banner-desktop d-none d-lg-block">
                  <Link
                    to={"/bikes/xcape-g1"}
                    className="home-cta-1 text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
                <div className="position-absolute w-100 doodle-pro-button-banner-mobile d-lg-none">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "1em" }}
                    >
                      <div className="btn-know-more text-uppercase rounded-5">
                        <Link
                          to={"/bikes/xcape-g1"}
                          style={{ color: "inherit" }}
                          className="my-2 text-decoration-none metropolis-bold"
                        >
                          BUY NOW
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <Link to={"/bikes/doodle-pro"} className="text-decoration-none">
                  <picture className="main-banner-img">
                    <source
                      media="(max-width:650px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Doodle+pro+Banner+Mobile.jpg"
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Doodle+pr+Banner+Web.jpg"
                    />
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Doodle+pr+Banner+Web.jpg"
                      alt="Banner1"
                      className="d-block w-100"
                    />
                  </picture>
                </Link>

                <div className="position-absolute doodle-pro-button-banner-desktop d-none d-lg-block">
                  <Link
                    to={"/bikes/doodle-pro"}
                    className="home-cta-1 text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
                <div className="position-absolute w-100 doodle-pro-button-banner-mobile d-lg-none">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "1em" }}
                    >
                      <div className="btn-know-more text-uppercase rounded-5">
                        <Link
                          to={"/bikes/doodle-pro"}
                          style={{ color: "inherit" }}
                          className="my-2 text-decoration-none metropolis-bold"
                        >
                          BUY NOW
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <Link to={"/t-rex-family"} className="text-decoration-none">
                  <picture className="main-banner-img">
                    <source
                      media="(max-width:650px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/main/Artboard+3+copy+3.jpg"
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/T-Rex+Pro+Desktop(1)(1).jpg"
                    />
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/T-Rex+Pro+Desktop(1)(1).jpg"
                      alt="Banner1"
                      className="d-block w-100"
                    />
                  </picture>
                </Link>

                <div className="position-absolute w-100 t-rex-family-button-banner">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "1em" }}
                    >
                      <div className="btn-know-more text-uppercase rounded-5">
                        <Link
                          to={"/t-rex-family"}
                          style={{ color: "inherit" }}
                          className="my-2 text-decoration-none metropolis-bold"
                        >
                          Know More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <Link
                  to={"/bikes/x1-pro-legend-07"}
                  className="text-decoration-none"
                >
                  <picture className="main-banner-img">
                    <source
                      media="(max-width:650px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Artboard+2+(1).jpg"
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
                    />
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
                      alt="Banner1"
                      className="d-block w-100"
                    />
                  </picture>
                </Link>

                <div className="position-absolute new-banner-cta-right-1 d-none d-lg-block">
                  <Link
                    to={"/bikes/x1-pro-legend-07"}
                    className="home-cta-1 text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
                <div className="position-absolute new-banner-cta-right d-lg-none">
                  <Link
                    to={"/bikes/x1-pro-legend-07"}
                    className="home-cta text-center text-decoration-none"
                    style={{
                      padding: "1em 2.5em",
                      fontFamily: "Metropolis-bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <Link to={"/bikes"} className="text-decoration-none">
                  <picture className="main-banner-img">
                    <source
                      media="(max-width:650px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Home+Page+Banner+(Mobile).jpg"
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Home+Page+Banner+(Desktop).jpg"
                    />
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Home+Page+Banner+(Desktop).jpg"
                      alt="Banner1"
                      className="d-block w-100"
                    />
                  </picture>
                </Link>

                <div className="position-absolute new-banner-cta-right d-none d-lg-block">
                  <Link
                    to={"/bikes"}
                    className="home-cta text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    EXPLORE NOW
                  </Link>
                </div>
                <div className="position-absolute new-banner-cta-right d-lg-none">
                  <Link
                    to={"/bikes"}
                    className="home-cta text-center text-decoration-none"
                    style={{
                      padding: "1em 2.5em",
                      fontFamily: "Metropolis-bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    EXPLORE NOW
                  </Link>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* ......Bike Cards Slider..... */}
        <h1
          className="fw-bold my-5 pb-3 text-center text-uppercase text-em heading-homepage"
          style={{
            fontFamily: "Metropolis-black",
            // letterSpacing: "1px",
          }}
        >
          Electric Cycle, Bicycle for Every Rider: Shop E-Cycles Online India
        </h1>
        <div className="mt-4 ps-3 ps-lg-5 pe-lg-5 ">
          <div className="bikes-card home-bike-card">
            <h2
              className="fw-bold mb-3 text-uppercase text-em heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                // letterSpacing: "1px",
              }}
            >
              Best Sellers
            </h2>
            <Swiper
              spaceBetween={15}
              slidesPerView={1.2}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              slide
              breakpoints={{
                1024: {
                  initialSlide: 4,
                  allowTouchMove: false,
                  slidesPerView: 4,
                  spaceBetween: 20,
                  loop: false,
                  slide: false,
                  noSwiping: true,
                },
              }}
            >
              {bikes &&
                bikes
                  .filter(
                    (item) =>
                      item.name == "X1" ||
                      item.name == "T-Rex Air" ||
                      item.name == "Doodle Pro" ||
                      item.name == "T-Rex Pro"
                  )
                  .map((item, index) => (
                    <SwiperSlide key={index} className="p-1 ">
                      <div
                        className="border position-relative rounded-3 p-2"
                        style={{
                          boxShadow:
                            "0px 0px 5px 5px rgba(0, 96, 214, 0.3), -0px -0px 1px 1px rgba(0, 96, 214, 0.3)",
                          overflow: "hidden",
                        }}
                      >
                        <h5
                          className="text-center text-uppercase m-0"
                          style={{
                            fontSize: "1.2rem",
                            fontFamily: "Metropolis-bold",
                          }}
                        >
                          {item.subCategory} range
                        </h5>
                        <Link
                          to={`/bikes/${item.urlName}`}
                          style={{ color: "inherit" }}
                          className="text-decoration-none"
                        >
                          <div>
                            <LazyLoadImage
                              src={item.cartImages[modelStates[index]]}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </Link>

                        <div className="ps-2  pe-2">
                          <div
                            className="d-flex mb-2 justify-content-center"
                            style={{ gap: "0.5em" }}
                          >
                            {item.models.map((model, colorIndex) => (
                              <button
                                key={colorIndex}
                                className={`rounded-circle home-bike-card-color border-0 ${
                                  modelStates[index] === colorIndex
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  pointer: "cursor",
                                  minWidth: "30px",
                                  minHeight: "30px",
                                  background: model.colorCode,
                                }}
                                onClick={() =>
                                  handleColorClick(index, colorIndex)
                                }
                              ></button>
                            ))}
                          </div>
                          <Link
                            to={`/bikes/${item.urlName}`}
                            style={{ color: "inherit" }}
                            className="text-decoration-none"
                          >
                            {" "}
                            <div className="mt-4 d-flex justify-content-between">
                              <h3
                                className="w-50 m-0 mb-2"
                                style={{
                                  fontSize: "1.6rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontWeight: "600",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {item.name === "X1 Limited Edition" ? (
                                  <>
                                    X1{" "}
                                    <span
                                      style={{
                                        fontSize: "0.6em",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Limited Edition
                                    </span>
                                  </>
                                ) : (
                                  item.name
                                )}
                              </h3>
                              <div>
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-half"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                              </div>
                            </div>
                            <p
                              className="m-0 mb-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontSize: "0.8em",
                                display: "-webkit-box",
                                maxWidth: "100%",
                                minHeight: "40px",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {item.cardDesc}
                            </p>
                            {/* <div
                              className="d-flex align-items-center mt-2 mb-3 justify-content-between"
                              style={{ gap: "1em" }}
                            >
                              <div className="" style={{ gap: "1em" }}>
                                <div className="d-flex justify-content-center mb-2">
                                  <img
                                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Frame.png"
                                    className="img-fluid mx-auto"
                                    style={{ width: "30%" }}
                                  ></img>
                                </div>
                                <div className="text-center">
                                  <p className="mb-0 fw-bold usp-font-size">
                                    {item.name !== "EMX+"
                                      ? "High Tensile"
                                      : "Aluminium 6061"}
                                  </p>
                                  <p className="mb-0 text-em fw-bold usp-font-size">
                                    {item.name !== "EMX+"
                                      ? "Steel Frame"
                                      : "Alloy Frame"}
                                  </p>
                                </div>
                              </div>
                              <div className="" style={{ gap: "1em" }}>
                                <div className="d-flex justify-content-center mb-2">
                                  <img
                                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Range.png"
                                    className="img-fluid mx-auto"
                                    style={{ width: "30%" }}
                                  ></img>
                                </div>
                                <div className="text-center">
                                  <p className="mb-0 fw-bold usp-font-size">
                                    {item.name === "EMX+"
                                      ? "80+ KM"
                                      : item.name === "T-Rex Air"
                                      ? "50+ KM"
                                      : "40+ KM"}
                                  </p>
                                  <p className="mb-0 text-em fw-bold usp-font-size">
                                    Range
                                  </p>
                                </div>
                              </div>
                              <div className="" style={{ gap: "1em" }}>
                                <div className="d-flex justify-content-center mb-2">
                                  <img
                                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Removable+Battery.png"
                                    className="img-fluid mx-auto"
                                    style={{ width: "30%" }}
                                  ></img>
                                </div>
                                <div className="text-center">
                                  <p className="mb-0 fw-bold usp-font-size">
                                    {item.name === "EMX+"
                                      ? "13AH"
                                      : item.name === "T-Rex Air"
                                      ? "10.2AH"
                                      : "7.65AH Removable"}
                                  </p>
                                  <p className="mb-0 text-em fw-bold usp-font-size">
                                    Battery
                                  </p>
                                </div>
                              </div>
                            </div> */}
                            <div
                              className="d-flex justify-content-start align-items-end"
                              style={{ gap: "0.5em" }}
                            >
                              <p style={{ fontSize: "0.9em" }} className="mb-2">
                                MRP{" "}
                                <span
                                  className="bold"
                                  style={{
                                    textDecoration: "line-through",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  {currencyFormat(item.slashPrice)}
                                </span>{" "}
                                <b className="text-em">
                                  &nbsp;
                                  {currencyFormat(item.accPrice)}
                                </b>
                              </p>
                              <div
                                className="mb-2 d-flex align-items-center"
                                style={{ color: "green", fontSize: "0.7em" }}
                              >
                                <small className="mb-1 fw-bold">
                                  {Math.trunc(
                                    ((item.slashPrice - item.accPrice) /
                                      item.slashPrice) *
                                      100
                                  )}
                                  % OFF
                                </small>
                              </div>
                            </div>
                          </Link>

                          <div className="d-flex mb-3">
                            <div className="w-100">
                              <Link
                                to={`/bikes/${item.urlName}`}
                                style={{
                                  fontSize: "0.8em",
                                }}
                                className="btn w-100 text-uppercase fw-bold text-decoration-none em-btn"
                              >
                                Buy Now{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </div>

        {/* range card */}
        <div
          className="range-section mt-5 mb-5 pt-3 d-lg-none"
          style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="ps-lg-5 pe-lg-5 ps-3 pb-3 pb-lg-0">
            <h1
              className="text-uppercase text-em mb-3 heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                fontWeight: "900",
              }}
            >
              Premium E-cycle Range{" "}
            </h1>
            <Swiper
              spaceBetween={15}
              slidesPerView={1.15}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              slide
              className="d-lg-none"
              onSlideChange={handleSlideChange}
            >
              {rangeData.map((item, index) => (
                <SwiperSlide key={index} className="p-1">
                  <div
                    className="border position-relative rounded-3 p-2"
                    style={{
                      boxShadow: "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                      overflow: "hidden",
                    }}
                  >
                    <h5
                      className="text-center fw-bold text-uppercase m-0"
                      style={{
                        fontSize: "1rem",
                        fontFamily: "Metropolis-bolder",
                      }}
                    >
                      {item.name} range
                    </h5>
                    <Link
                      to={`/bikes#${item.name}`}
                      style={{ color: "inherit" }}
                      className="text-decoration-none"
                    >
                      <div>
                        <LazyLoadImage
                          src={item.url2}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="text-center mt-4">
              {activeSwiper === 4 ? (
                <Link
                  to={"/bikes/range/x-factor"}
                  className="em-btn btn p-2 text-center text-uppercase text-decoration-none"
                  style={{ fontSize: "0.8em" }}
                >
                  EXPLORE x-factor range
                </Link>
              ) : (
                <Link
                  to={"/bikes/range/desire"}
                  className="em-btn btn p-2 text-center text-uppercase text-decoration-none"
                  style={{ fontSize: "0.8em" }}
                >
                  EXPLORE desire range
                </Link>
              )}
            </div>
            <div className=" col-12 d-none">
              <div className="d-flex" style={{ gap: "1em" }}>
                {rangeData.map((item) => (
                  <div
                    className="card-doodle"
                    onMouseOver={() => handleHover(item.id)}
                    key={item.id}
                    style={{
                      maxWidth: activeItem === item.id ? "70%" : "15%",
                      flexGrow: activeItem === item.id ? 4 : 1,
                      // backgroundImage: `url(${item.url1})`,
                      boxShadow:
                        activeItem === item.id
                          ? "0px 0px 10px rgba(0, 77, 172)"
                          : "0px 0px 20px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <img src={item.url1} className="w-50"></img>
                      </div>
                    </div>

                    {activeItem !== item.id && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0%",
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          backgroundColor: "black",
                          background:
                            "linear-gradient(to bottom, rgba(255,255,255,0), black)",
                        }}
                      />
                    )}
                    {activeItem !== item.id && (
                      <div className="w-100">
                        <p
                          className="position-absolute text-uppercase text-light d-none d-lg-block"
                          style={{
                            bottom: "10%",
                            right: "5%",
                            transform: "rotate(270deg)",
                          }}
                        >
                          Drag here
                        </p>
                        <p
                          className="position-absolute text-uppercase text-light d-lg-none"
                          style={{
                            bottom: "10%",
                            right: "5%",
                            transform: "rotate(270deg)",
                          }}
                        >
                          Click here
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* breaker */}
        <div>
          <picture className="main-banner-img mt-3 mt-lg-5 ">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/breaker/Home+Page+Breaker+(Mobile).jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/breaker/Home+Page+Breaker+(Desktop).jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/breaker/Home+Page+Breaker+(Desktop).jpg"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
        </div>

        {/* gif section */}
        <div className=" w-100 mt-5 mt-lg-5 ">
          <div className="ps-lg-5 pe-lg-5 ps-3">
            <h1
              className="fw-bold mb-3 text-uppercase text-em heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
              }}
            >
              smart features, smart rides
            </h1>
          </div>

          <div className="row m-0">
            <div className="col-lg-8 col-12 p-0 mb-lg-0 mb-2">
              <div className="position-relative">
                {window.innerWidth <= 650 ? (
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="w-100"
                  >
                    <source
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Desktop(Battery).mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <LazyLoadImage
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(Battery).gif"
                    alt="Banner1"
                    className="w-100"
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom, transparent, black)",
                  }}
                />
                <div className="position-absolute desk-gif-1">
                  <h2
                    className="text-uppercase text-light m-0 mobile-gif-heading"
                    style={{
                      fontFamily: "Metropolis-bolder",
                      fontWeight: "900",
                    }}
                  >
                    Charge anywhere
                  </h2>
                  <p className="text-light m-0 mb-2 gif-content">
                    Like your phone, plug it in, and zip-zap-zoom!
                  </p>
                  <a
                    href="https://www.emotorad.com/blog/clj2wz1fu2p6y0apiys2ntrv1"
                    className="em-btn btn p-2 text-center text-decoration-none gif-btn"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 p-0 ps-lg-3">
              <div className="d-flex d-lg-block " style={{ gap: "0.5em" }}>
                <div className="position-relative mb-0 mb-lg-3">
                  {window.innerWidth <= 650 ? (
                    <video
                      autoPlay
                      muted
                      loop
                      disableRemotePlayback
                      playsInline
                      className="w-100"
                    >
                      <source
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Mobile(Motor).mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <LazyLoadImage
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(Motor).gif"
                      alt="Banner1"
                      className="w-100"
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom, transparent, black)",
                    }}
                  />
                  <div className="position-absolute desk-gif-2 pe-2">
                    <h3
                      className="text-uppercase text-light m-0 mobile-gif-heading"
                      style={{
                        fontFamily: "Metropolis-bolder",
                        fontWeight: "900",
                      }}
                    >
                      SWEAT BY CHOICE
                    </h3>
                    <p className="text-light m-0 mb-2 gif-content d-none d-lg-block">
                      Pedal or accelerate, either way, we got your back
                    </p>
                    <p className="text-light m-0 mb-2 gif-content d-lg-none">
                      Pedal or accelerate, we got you
                    </p>
                    <a
                      href="https://www.emotorad.com/blog/clsuelnqq1axh0bmr78wkfnfh"
                      className="em-btn btn p-2 text-center text-decoration-none gif-btn"
                    >
                      Know More
                    </a>
                  </div>
                </div>
                <div className="position-relative">
                  <picture>
                    <source
                      media="(max-width:650px)"
                      srcSet={
                        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/display-gif-mobile.gif"
                      }
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet={
                        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(display).gif"
                      }
                    />
                    <LazyLoadImage
                      src={
                        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(display).gif"
                      }
                      alt="Banner1"
                      className="w-100"
                    />
                  </picture>
                  <div
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom, transparent, black)",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom, transparent, black)",
                    }}
                  />
                  <div className="position-absolute desk-gif-3 pe-2">
                    <h3
                      className="text-uppercase text-light m-0 mobile-gif-heading"
                      style={{
                        fontFamily: "Metropolis-bolder",
                        fontWeight: "900",
                      }}
                    >
                      Lifetime Warranty*
                    </h3>
                    <p className="text-light m-0 mb-2 gif-content d-lg-block d-none">
                      Rest assured we'll take care of you, or your bike at
                      least!
                    </p>
                    <p className="text-light m-0 mb-2 gif-content d-lg-none">
                      We will take care of you
                    </p>
                    <a
                      href="https://www.emotorad.com/blog/clj2wz1fu2p6y0apiys2ntrv1"
                      className="em-btn btn p-2 text-center text-decoration-none gif-btn"
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modes */}
        <div className="mt-5 ps-lg-5 pe-lg-5 ps-3 mb-1">
          <h2
            className="text-em mb-1 fw-bold text-uppercase heading-homepage"
            style={{
              fontFamily: "Metropolis-black",
              fontWeight: "900",
            }}
          >
            A mode for every mood{" "}
          </h2>
          <div className="blogs-card container-lg pt-2 ps-lg-5  pb-lg-5 mb-2 mb-lg-0 pe-lg-5 ps-0 pe-0">
            <Swiper
              spaceBetween={14}
              slidesPerView={1.2}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              slide
            >
              <SwiperSlide className="p-1  h-100 rounded-3">
                <div
                  className=" rounded-3 p-2 h-100"
                  style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.40)" }}
                >
                  <div className=" rounded-3 p-2">
                    <div>
                      {window.innerWidth <= 650 ? (
                        <video
                          autoPlay
                          muted
                          loop
                          disableRemotePlayback
                          playsInline
                          className="rounded-3 img-fluid"
                        >
                          <source
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/thrttle-1.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <LazyLoadImage
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/thrttle-1.gif"
                          alt="Banner1"
                          className="img-fluid rounded-3"
                        />
                      )}
                    </div>

                    <h4
                      className="fw-bold ps-1 mt-3 mb-1"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      ACCELERATE
                    </h4>
                    <div className="d-flex ps-1 justify-content-between">
                      <span style={{ fontSize: "0.9rem", width: "87%" }}>
                        Twist the throttle and zoom through without pedalling
                      </span>
                      <a
                        href="https://www.emotorad.com/blogs/modes-of%20electric-cycles"
                        className="text-decoration-none em-hover"
                      >
                        <Icon icon="mynaui:arrow-long-right" width={"30"} />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1   h-100 rounded-3">
                <div
                  className=" rounded-3 p-2 h-100"
                  style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.40)" }}
                >
                  <div className=" rounded-3 p-2">
                    <div>
                      {window.innerWidth <= 650 ? (
                        <video
                          autoPlay
                          muted
                          loop
                          disableRemotePlayback
                          playsInline
                          className="rounded-3 img-fluid"
                        >
                          <source
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/PAS_1.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <LazyLoadImage
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/PAS_1.gif"
                          alt="Banner1"
                          className="img-fluid rounded-3"
                        />
                      )}
                    </div>

                    <h4
                      className="fw-bold ps-1 mt-3 mb-1"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      PEDAL ASSIST
                    </h4>
                    <div className="d-flex ps-1 justify-content-between">
                      <span style={{ fontSize: "0.9rem", width: "75%" }}>
                        Your pedal power meets our electric magic
                      </span>
                      <a
                        href="https://www.emotorad.com/blogs/modes-of%20electric-cycles"
                        className="text-decoration-none em-hover"
                      >
                        <Icon icon="mynaui:arrow-long-right" width={"30"} />
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1   h-100 rounded-3">
                <div
                  className=" rounded-3 p-2 h-100"
                  style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.40)" }}
                >
                  <div className=" rounded-3 p-2">
                    <div>
                      {window.innerWidth <= 650 ? (
                        <video
                          autoPlay
                          muted
                          loop
                          disableRemotePlayback
                          playsInline
                          className="rounded-3 img-fluid"
                        >
                          <source
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Mechanical_1_1.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <LazyLoadImage
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Mechanical_1_1.gif"
                          alt="Banner1"
                          className="img-fluid rounded-3"
                        />
                      )}
                    </div>

                    <h4
                      className="fw-bold ps-1 mt-3 mb-1"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      MECHANICAL
                    </h4>
                    <div className="d-flex ps-1 justify-content-between">
                      <span style={{ fontSize: "0.9rem", width: "85%" }}>
                        Embrace nostalgia and go old school with pedal mode
                      </span>
                      <a
                        href="https://www.emotorad.com/blogs/modes-of%20electric-cycles"
                        className="text-decoration-none em-hover"
                      >
                        <Icon icon="mynaui:arrow-long-right" width={"30"} />
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* celebs */}
        <div
          className=" position-relative "
          style={{
            minHeight: "400px",
          }}
        >
          <div className="mt-4  mb-3 ps-lg-5 pe-lg-5 ps-3 pe-3">
            <h2
              className="text-em fw-bold text-uppercase heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                fontWeight: "900",
              }}
            >
              E-cycles caught posing!{" "}
            </h2>
          </div>

          <div
            className="position-relative"
            style={{
              backgroundImage:
                'url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/ABout%2BUs.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "350px",
            }}
          >
            <div
              className="position-absolute h-100 w-100"
              style={{ background: "rgba(0,0,0,0.6)" }}
            ></div>
            <h3
              className="position-absolute text-light w-100 text-center"
              style={{ fontFamily: "Metropolis-bolder", top: "30%" }}
            >
              “MOST PROMISING EV OF THE YEAR”{" "}
            </h3>{" "}
            <p
              className="text-light mt-1 w-100 text-center celebs-heading text-uppercase"
              style={{ fontSize: "0.9em" }}
            >
              That’s what they said
            </p>
          </div>
          <div className="ps-3" style={{ marginTop: "-5em" }}>
            <Swiper
              spaceBetween={5}
              slidesPerView={1.3}
              autoplay={true}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 6.2,
                  spaceBetween: 20,
                },
              }}
              slide
            >
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Bhaichung.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Sonali.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Isha.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Jayant.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Nitin.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Shaan.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Suniel.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Terence.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Neha+Dhupia.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Angad+Bedi.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
            </Swiper>
          </div>
        </div>
        {/* extras */}
        <div className="my-5 px-3 ps-lg-5 pe-lg-5 py-lg-5 py-3">
          <h2
            className="fw-bold mb-3 text-em heading-homepage"
            style={{
              fontFamily: "Metropolis-black",
              // letterSpacing: "1px",
            }}
          >
            Why Electric Cycles?
          </h2>
          <p className="question-content">
            An electric bike, or e-cycle, brings the thrill of riding a
            motor-integrated, throttle-enabled bicycle with numerous benefits,
            making it immensely relevant to today's commuting needs.
          </p>
          <ul className="mb-0">
            <li className="question-content">
              <p className="mb-0">
                <b>Eco-Friendly Travel</b>
              </p>
              <p className="mb-0">
                With no direct emissions, e-cycles are the perfect green
                alternative to cars, scooters, and motorcycles. They help
                prevent air pollution and contribute to a more sustainable
                future by reducing carbon footprints.
              </p>
            </li>
            <li className="question-content">
              <p className="mb-0">
                <b>Health and Fitness</b>
              </p>
              <p className="mb-0">
                E-cycles provide the best of both worlds - assisted riding
                whenever necessary and the choice to pedal at other times. The
                rider can adjust the pedalling effort according to their fitness
                level, keeping them engaged for a long time without too much
                physical strain.Cycling is a fun, low-impact form of exercise
                that is great for joint health and supports cardiovascular
                fitness while letting the rider enjoy the feel of the journey.
              </p>
            </li>
          </ul>
          <div className="collapse" id="collapseEx">
            <ul>
              <li className="question-content">
                <p className="mb-0">
                  <b>Economical</b>
                </p>
                <p className="mb-0">
                  Since running costs are virtually nil, e-cycles are a cheap
                  means of transport. The cost of charging the battery is far
                  cheaper than the cost of fuel, and bike maintenance is also
                  way cheaper. Cyclists are also exempted from parking space
                  charges and road/traffic tolls, making it a very affordable
                  mode of commuting on a daily basis.
                </p>
              </li>
              <li className="question-content">
                <p className="mb-0">
                  <b>Convenient and Flexible</b>
                </p>
                <p className="mb-0">
                  E-bikes enable more efficient movement across cities,
                  lessening commute strain and time. The acceleration option
                  ensures that a rider can ride longer distances with relative
                  ease, while its all-terrain feature guarantees good riding
                  experiences both on and off-road trails.
                </p>
              </li>
              <li className="question-content">
                <p className="mb-0">
                  <b>Inclusive and Accessible</b>
                </p>
                <p className="mb-0">
                  E-cycles are designed for users of all ages and health
                  statuses, making them accessible and inclusive for all. This
                  is particularly helpful when cycling through hilly or
                  difficult regions, where the motor plays an important role in
                  enabling riders to conquer steep climbs with ease, encouraging
                  a more active lifestyle.
                </p>
              </li>
            </ul>
            <p className="question-content mb-5">
              E-cycles are an environmentally friendly, affordable, and
              versatile form of transportation. They make every journey more
              enjoyable while promoting comfort and health.
            </p>
            <div className="mb-5">
              <h2
                className="fw-bold mb-3 text-em heading-homepage"
                style={{
                  fontFamily: "Metropolis-black",
                  // letterSpacing: "1px",
                }}
              >
                Why Choose Emotorad E Cycles? Advanced Features of Our Electric
                Cycles.
              </h2>
              <div className="question-content">
                <p className="">
                  EMotorad is reimagining the future of travel with eco-friendly
                  e-cycles that serve both urban commuters and adventure
                  seekers. With over four years of service and 50,000+ satisfied
                  customers, we have established ourselves as a reliable and
                  innovative brand in the market.
                </p>
                <p>
                  <b>EMotorad E-Cycle Advanced Features:</b>
                </p>
                <ul>
                  <li className="">
                    <p className="mb-0">
                      <b>Digital Display</b>
                    </p>
                    <p className="mb-0">
                      The advanced digital display distinctly presents speed,
                      battery, distance, and pedal assist information. It also
                      showcases error codes to keep your ride safe and informed.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Long-lasting Battery</b>
                    </p>
                    <p className="mb-0">
                      The removable battery enables rides of over 70 km on a
                      single charge, making it ideal for long-distance commutes
                      and weekend excursions. It is simple to recharge and
                      designed for durability.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>High-Performance Motor & Pedal Assist</b>
                    </p>
                    <p className="mb-0">
                      Built with a 250W electric motor and 5 levels of pedal
                      assist, it can easily switch between speeds and modes at
                      will. The 7-Shimano gear system ensures smooth operations
                      and more control on every type of road.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Safety Lighting</b>
                    </p>
                    <p className="mb-0">
                      Enjoy safe riding with built-in front lights, backlights,
                      indicators and emergency lights, whether day or night.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Durable Build</b>
                    </p>
                    <p className="mb-0">
                      The frame material is robust and sturdy yet lightweight
                      and can sustain all-terrain riding.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Front Suspension</b>
                    </p>
                    <p className="mb-0">
                      The 100mm front suspension with lockout adjustment
                      guarantees a comfortable, bump-free ride on every terrain.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-5">
              <h2
                className="fw-bold mb-3 text-em heading-homepage"
                style={{
                  fontFamily: "Metropolis-black",
                  // letterSpacing: "1px",
                }}
              >
                Different Types of Electric Cycles. Electric Bicycle Purchase
                Guide.
              </h2>
              <div className="question-content">
                <p className="">
                  When choosing an e-cycle, choosing one that will suit your
                  riding style is important. Given below are the major kinds of
                  available e-cycles:
                </p>
                <ul>
                  <li className="">
                    <p className="mb-0">
                      <b>Mountain E-Cycles</b>
                    </p>
                    <p className="mb-0">
                      Suitable for extreme sports and off-site activities,
                      <a href="https://www.emotorad.com/bikes/emx">
                        mountain electric cycles
                      </a>{" "}
                      feature efficient motors, a sturdy build, and advanced
                      suspension for rough terrain. Electric efficiency meets
                      adventure in this variety of bicycles, making conquering
                      hills and rocky paths more manageable.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Foldable E-Cycles</b>
                    </p>
                    <p className="mb-0">
                      Ideal for urban riders,{" "}
                      <a href="https://www.emotorad.com/bikes/doodle-v3">
                        foldable electric cycles
                      </a>{" "}
                      make it easy to carry the bikes wherever you go. These
                      lightweight bicycles are convenient since they do not
                      require much storage space. It is ideal for riders who
                      travel with their bicycles in buses or store them in small
                      spaces.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Commuter E-Cycles</b>
                    </p>
                    <p className="mb-0">
                      As the name suggests, these cycle types are best suited
                      for city riding. Their sleek tyres and electric pedal
                      assist make tackling traffic easier, which is highly
                      appropriate for daily rides to work or around the city.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Fat Tyre E-Cycles</b>
                    </p>
                    <p className="mb-0">
                      The fat tyres in e-cycles enable ideal performance in
                      various conditions, whether it’s sandy, snowy, or muddy.
                      These large tyres make it easier for the e-cycle to stay
                      steady on the ground, allowing for agility even on rugged
                      grounds.
                    </p>
                  </li>
                </ul>
                <p className="mb-3">
                  <b>Buying Recommendations for Electric Bicycle</b>
                </p>
                <p className="mb-0">
                  In case this is the first time you are setting out to procure
                  an e-cycle, these are a few factors you need to reflect on:
                </p>
                <ul>
                  <li className="">
                    <p className="mb-0">
                      <b>Purpose</b>
                    </p>
                    <p className="mb-0">
                      Establish what you will be using the e-cycle
                      for—commuting, off-roading, or maybe a leisure ride. This
                      will help you in choosing the appropriate one. There are
                      commuter, mountain, and folding e-cycles available for
                      your specific purpose.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Power of Motor</b>
                    </p>
                    <p className="mb-0">
                      Look for a motor as per your riding style. A 250W motor
                      would be fine for riding around the city and in hilly
                      terrain.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Battery Capacity</b>
                    </p>
                    <p className="mb-0">
                      Think about the distance you will ride. Usually, most{" "}
                      <a href="https://www.emotorad.com/blogs/e-cycle-range">
                        e-cycles range
                      </a>{" "}
                      between 50-100km per charge. The battery should be
                      convenient to carry and remove, which is ideal if e-cycles
                      are used for regular and long-distance commuting.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Ease of Use & Structure</b>
                    </p>
                    <p className="mb-0">
                      If you’re going for rides in the city, go for light
                      frames. However, if you’re doing off-road cycling, more
                      rugged and heavier frames will do the trick. Look out for
                      suspension systems that suit your type of terrain.
                    </p>
                  </li>
                  <li className="">
                    <p className="mb-0">
                      <b>Cost</b>
                    </p>
                    <p className="mb-0">
                      Advanced features will always come with a price tag. If
                      you wish to remain within your financial limit,
                      concentrate on the essentials—for example, motor strength
                      and battery life—rather than cost-enhancing extras.
                    </p>
                  </li>
                </ul>
                <p className="mb-0">
                  Considering these aspects will help you choose an e-cycle
                  suitable for your riding style and lifestyle.
                </p>
              </div>
            </div>
            <div className="mb-5">
              <h2
                className="fw-bold mb-3 text-em heading-homepage"
                style={{
                  fontFamily: "Metropolis-black",
                  // letterSpacing: "1px",
                }}
              >
                E-Cycle Riding Tips for Beginners
              </h2>
              <div className="question-content">
                <p className="mb-2">
                  The first time you ride an electric bicycle is thrilling
                  beyond one’s imagination. This adventure is well-balanced with
                  fun activities, exercises, and environmentally friendly means
                  of transport.
                </p>
                <p className="mb-2">
                  Nonetheless, it is important to know some basic tips to ensure
                  a smoother, safer ride. Whether it is learning how to pedal
                  the e-cycle or controlling the extra power of the motor, there
                  are a couple of things all riders have to bear in mind to
                  fully enjoy the e-cycling experience.
                </p>
                <p className="mb-2">
                  If you are looking for step-by-step instructions on how to
                  ride an e-cycle for the first time - the very ins and outs -
                  check out this blog:{" "}
                  <a href="https://www.emotorad.com/blogs/how-to-ride-bicycle-for-first-time">
                    How to Ride a Bicycle for the First Time
                  </a>
                  .
                </p>
              </div>
            </div>

            <h2
              className="fw-bold mb-3 text-em heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                // letterSpacing: "1px",
              }}
            >
              FAQs
            </h2>
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse1"
                  aria-expanded={isQuestionOne ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionOneCollapse}
                >
                  <h2 className="faq-heading text-em">
                    1. What is an E Cycle?
                  </h2>
                  <span className="iconify">
                    {isQuestionOne ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-2 question-content" id="collapse1">
                  <p className="ps-4">
                    An electric cycle is a bicycle with a motor and battery,
                    allowing riders to utilise some fascinating features like
                    the pedal assist and throttle, alongside normal pedalling.
                    This feature makes it perfect for every rider, irrespective
                    of age and fitness level.
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionOne && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  aria-expanded={isQuestionTwo ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionTwoCollapse}
                >
                  <h2 className="faq-heading text-em">
                    2. How do electric cycles work?
                  </h2>
                  <span className="iconify">
                    {isQuestionTwo ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-2 question-content" id="collapse2">
                  <p className="ps-4">
                    Electric cycles (e-cycles) combine a battery-powered motor
                    with traditional pedal power. The motor assists the rider,
                    reducing the effort exerted, especially on hills or long
                    distances. Riders can adjust the level of assistance through
                    pedal-assist modes. Check out this article to learn more:{" "}
                    <a href="https://www.emotorad.com/blogs/what-is-an-electric-cycle">
                      What is an E-cycle and How Does it Work?
                    </a>
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionTwo && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  aria-expanded={isQuestionThree ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionThreeCollapse}
                >
                  <h2 className="faq-heading text-em">
                    3. What is the average speed of electric cycles?
                  </h2>
                  <span className="iconify">
                    {isQuestionThree ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-2 question-content" id="collapse3">
                  <p className="ps-4">
                    Most e-cycles have a top speed of around 25-30 km/h,
                    depending on the motor power and local regulations.
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionThree && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded={isQuestionFour ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionFourCollapse}
                >
                  <h2 className="faq-heading text-em">
                    4. What is the average range of e-cycles?
                  </h2>
                  <span className="iconify">
                    {isQuestionFour ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-2 question-content" id="collapse4">
                  <p className="ps-4">
                    On a full charge, e-cycles like EMotorad models offer an
                    average 50-70 km range, depending on terrain, rider weight,
                    and pedal assist level. Check out this article to learn
                    more:{" "}
                    <a href="https://www.emotorad.com/blogs/e-cycle-range">
                      E-cycle Range: All You Need to Know
                    </a>
                  </p>

                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionFour && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse5"
                  aria-expanded={isQuestionFive ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionFiveCollapse}
                >
                  <h2 className="faq-heading text-em">
                    5. How much time does it take to charge an e-bike?
                  </h2>
                  <span className="iconify">
                    {isQuestionFive ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-2 question-content" id="collapse5">
                  <p className="ps-4">
                    Charging times vary, but it typically takes around 4-6 hours
                    to fully charge an e-bike
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionFive && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse6"
                  aria-expanded={isQuestionSix ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionSixCollapse}
                >
                  <h2 className="faq-heading text-em">
                    6. Are electric cycles waterproof?
                  </h2>
                  <span className="iconify">
                    {isQuestionSix ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-2 question-content" id="collapse6">
                  <p className="ps-4">
                    EMotorad e-cycles are designed to handle light rain and wet
                    conditions, but it's important to avoid submerging
                    electrical components in water. Check out this article to
                    learn more:{" "}
                    <a href="https://www.emotorad.com/blogs/rainproof-your-e-Cycle">
                      How to Rainproof Your E-Cycle
                    </a>
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionSix && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse7"
                  aria-expanded={isQuestionSeven ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionSevenCollapse}
                >
                  <h2 className="faq-heading text-em">
                    7. How safe are electric cycles?
                  </h2>
                  <span className="iconify">
                    {isQuestionSeven ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-3 question-content" id="collapse7">
                  <p className="ps-4">
                    Electric cycles are generally safe with proper use. They
                    feature safety mechanisms like brake cut-off systems,
                    lights, and indicators. Regular maintenance ensures optimal
                    safety. Check out this article to learn more:{" "}
                    <a href="https://www.emotorad.com/blogs/top-ten-tips-to-commute-on-electric-cycle">
                      Electric Cycle Safety Tips
                    </a>
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionSeven && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse8"
                  aria-expanded={isQuestionEight ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionEightCollapse}
                >
                  <h2 className="faq-heading text-em">
                    8. Are there any electric cycle models available for kids?
                  </h2>
                  <span className="iconify">
                    {isQuestionEight ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-3 question-content" id="collapse8">
                  <p className="ps-4">
                    Some EMotorad e-cycle models come with lower frame heights
                    more suitable for young riders. The Lil E kick scooter is
                    meant for kids. Always check height and weight
                    recommendations before purchasing an e-cycle.
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionEight && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
            <div>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse9"
                  aria-expanded={isQuestionNine ? "true" : "false"}
                  aria-controls="collapseExample"
                  onClick={toggleQuestionNineCollapse}
                >
                  <h2 className="faq-heading text-em">
                    9. Is an electric cycle legal in India?
                  </h2>
                  <span className="iconify">
                    {isQuestionNine ? (
                      <i className="fa-solid fa-minus"></i>
                    ) : (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </span>
                </div>

                <div className="collapse mt-3 question-content" id="collapse9">
                  <p className="ps-4">
                    Yes, electric cycles are legal in India. E-cycles with
                    speeds below 25 km/h and power under 250W do not require a
                    license or registration, making all EMotorad e-cycles
                    perfect for everyone.<br></br>Check out this article to
                    learn more:{" "}
                    <a href="https://www.emotorad.com/blogs/license-drive-an-electric-cycle">
                      Do You Need License to Drive Electric Cycle in India?{" "}
                    </a>
                  </p>
                  <hr className="p-0" style={{ marginTop: "14px" }} />
                </div>
              </div>
            </div>
            {!isQuestionNine && (
              <hr className="p-0" style={{ marginTop: "16px" }} />
            )}
          </div>
          <div className="">
            <button
              className="spec-btn mt-4 mb-4 d-block"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEx"
              aria-expanded="false"
              onClick={() => setCollapse(!Collapse)}
              aria-controls="collapseExample"
            >
              {Collapse ? "Collapse" : " See More"}
            </button>
          </div>
        </div>

        {/* .....Blogs...... */}
        <div className="mt-5 ps-lg-5 pe-lg-5 ps-3 pe-3 mb-lg-0 mb-3">
          <h2
            className="text-uppercase text-em mb-3 heading-homepage"
            style={{
              fontFamily: "Metropolis-black",
              fontWeight: "900",
            }}
          >
            EM Knowledge hub
          </h2>
          <div className="blogs-card mt-0 pt-1  pb-lg-5 mt-lg-3 pe-0 mb-2 ps-0 p-0">
            <Swiper
              spaceBetween={15}
              slidesPerView={1.3}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  noSwiping: true,
                  allowTouchMove: false,
                  loop: false,
                  slide: false,
                },
              }}
              slide
            >
              <SwiperSlide className="p-1 rounded-3">
                <div
                  className=" rounded-3 p-3 "
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"/blogs/what-is-an-electric-cycle"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoad>
                        <img
                          src="https://media.graphassets.com/cdYXwMvDQHSIXD92Pbir"
                          alt=""
                          className="img-fluid rounded-2"
                        />
                      </LazyLoad>
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Friday, 28 April 2023
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      E-cycling for Dummies: Here’s Everything You Need to Know!
                    </h6>
                    <p
                      className="text-muted"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        fontSize: "0.9em",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      Don't know what e-cycles are? Is it just a cycle? Let's
                      dive in and find out.
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1  h-100 rounded-3">
                <div
                  className=" rounded-3 p-3 h-100"
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"/blogs/anatomy-electric-cycle"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoad>
                        <img
                          src="https://media.graphassets.com/1j6AdmqqQ6elXTLIUo0n"
                          alt=""
                          className="img-fluid rounded-2"
                        />
                      </LazyLoad>
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Wednesday, 21 February 2024
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      Anatomy of a Great Electric Cycle
                    </h6>

                    <p
                      className="text-muted"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        fontSize: "0.9em",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      Discover the anatomy of an electric cycle, from the frame
                      to the motor, battery, control system, brakes, suspension,
                      terrain considerations, and lights.
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 h-100 rounded-3">
                <div
                  className=" rounded-3 p-3 h-100"
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"blogs/common-misconceptions-about-electric-eikes"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoad>
                        <img
                          src="https://media.graphassets.com/R0iJZvUyQ4KxXAI4oDNv"
                          alt=""
                          className="img-fluid rounded-2"
                        />
                      </LazyLoad>
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Tuesday, 18 July 2023
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      Common Misconceptions About Electric Cycles (and how we
                      bust them!)
                    </h6>
                    <p
                      className="text-muted "
                      style={{
                        display: "-webkit-box",
                        fontSize: "0.9em",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      Learn the real deal about electric cycles. We're busting
                      myths about speed, laziness, and more. Empower yourself
                      with facts and ride on with confidence!
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1 h-100 rounded-3">
                <div
                  className=" rounded-3 p-3 h-100"
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"/blogs/electriccycle-care"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoadImage
                        src="https://media.graphassets.com/T6q2GI93RYusdPG5DddR"
                        alt=""
                        className="img-fluid rounded-2"
                      />
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Saturday, 3 February 2024
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      Reasons Why You Should Care About Electric Cycles
                    </h6>
                    <p
                      className="text-muted "
                      style={{
                        display: "-webkit-box",
                        fontSize: "0.9em",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      From extended range to zero emissions, explore the
                      compelling reasons why electric cycles should be on your
                      radar.
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};

export default HomeScreen;
