import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Meta from "../Components/Meta";
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../CSS/blog.css";

import SwiperCore, { Pagination, Autoplay, EffectCoverflow } from "swiper";

const BlogsScreen = () => {
  SwiperCore.use([Pagination, Autoplay, EffectCoverflow]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [blogPosts, setBlogPosts] = useState([]);
  const hygraph = new GraphQLClient(
    "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
  );

  const QUERY = gql`
    {
      posts(orderBy: date_DESC) {
        id
        title
        slug
        tags
        date
        excerpt
        blogCategory
        coverImage {
          url
        }
        content {
          html
        }
      }
    }
  `;
  useEffect(() => {
    const getBlogs = async () => {
      const { posts } = await hygraph.request(QUERY);
      setBlogPosts(posts);
    };
    getBlogs();
  }, []);

  const categoryMapping = {
    latestUpdates: "Latest Updates",
    productScoop: "Product Scoop",
    dailyDash: "Daily Dash",
    tipsTricks: "Tips & Tricks",
    vibezEvents: "Vibez & Events",
    pedalTales: "Pedal Tales",
  };

  const [currentCategory, setCurrentCategory] = useState("latestUpdates");

  return (
    <>
      <>
        <Navbar breadcrumb={true} />
        <Meta
          title={" EMotorad | Blogs"}
          description={
            "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
          }
        />
        {blogPosts &&
          blogPosts.filter(
            (post) => post.blogCategory !== "bharatOnEMotorad"
          )[0] && (
            <div className="container py-5">
              <Link
                to={`/blogs/${
                  blogPosts.filter(
                    (post) => post.blogCategory !== "bharatOnEMotorad"
                  )[0]?.slug
                }`}
                className="text-decoration-none text-dark"
              >
                <div
                  className="d-flex flex-column flex-lg-row nowrap blog-div"
                  style={{ gap: "2em" }}
                >
                  <div>
                    <img
                      src={
                        blogPosts.filter(
                          (post) => post.blogCategory !== "bharatOnEMotorad"
                        )[0]?.coverImage?.url
                      }
                      className="img-fluid"
                      alt="Blog Cover"
                    />
                  </div>
                  <div>
                    <h2
                      className="mb-3 blog-heading"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      {
                        blogPosts.filter(
                          (post) => post.blogCategory !== "bharatOnEMotorad"
                        )[0]?.title
                      }
                    </h2>
                    <div className="d-flex justify-content-between mb-4">
                      {blogPosts.filter(
                        (post) => post.blogCategory !== "bharatOnEMotorad"
                      )[0]?.blogCategory && (
                        <p
                          style={{ fontSize: "0.9em" }}
                          className="text-em m-0 blog-category fw-bold blog-category"
                        >
                          {
                            categoryMapping[
                              blogPosts.filter(
                                (post) =>
                                  post.blogCategory !== "bharatOnEMotorad"
                              )[0].blogCategory
                            ]
                          }
                        </p>
                      )}
                      <p
                        style={{ fontSize: "0.9em" }}
                        className="text-muted m-0"
                      >
                        <span className="pe-2 text-muted">
                          {
                            months[
                              new Date(
                                blogPosts.filter(
                                  (post) =>
                                    post.blogCategory !== "bharatOnEMotorad"
                                )[0].date
                              ).getMonth()
                            ]
                          }{" "}
                          {""}
                          {new Date(
                            blogPosts.filter(
                              (post) => post.blogCategory !== "bharatOnEMotorad"
                            )[0].date
                          ).getDate()}
                          ,{" "}
                          {new Date(
                            blogPosts.filter(
                              (post) => post.blogCategory !== "bharatOnEMotorad"
                            )[0].date
                          ).getFullYear()}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-muted m-0">
                        {
                          blogPosts.filter(
                            (post) => post.blogCategory !== "bharatOnEMotorad"
                          )[0].excerpt
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )}
        {/* desktop cards */}
        <div className="container my-5">
          <div
            className="d-flex mb-5 pb-2 blog-categories"
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          >
            {Object.entries(categoryMapping).map(([key, value]) => (
              <div key={value} style={{ cursor: "pointer" }}>
                <h5
                  className={
                    key === currentCategory
                      ? "pb-2 blog-category-active"
                      : "pb-2"
                  }
                  onClick={() => setCurrentCategory(key)}
                  style={{ fontFamily: "Metropolis-bold", fontSize: "1.1em" }}
                >
                  {value}
                </h5>
              </div>
            ))}
          </div>
          <div className="">
            <div className="row">
              {blogPosts &&
                blogPosts
                  .filter(
                    (post) =>
                      currentCategory === "latestUpdates" ||
                      post.blogCategory === currentCategory
                  )
                  .filter((post) => post.blogCategory !== "bharatOnEMotorad")
                  .slice(
                    currentCategory === "latestUpdates" ? 1 : 0,
                    currentCategory === "latestUpdates" ? 7 : blogPosts.length
                  )
                  .map((post, i) => (
                    <div key={i} className="col-md-4 col-12 mb-4 blog-div">
                      <Link
                        to={`/blogs/${post.slug}`}
                        className="text-decoration-none text-dark"
                      >
                        <div
                          className=" rounded-3  h-100"
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <div>
                            <img
                              src={post?.coverImage?.url}
                              alt=""
                              className="img-fluid rounded-2"
                            />
                          </div>
                          <div className="p-3">
                            <div className="d-flex justify-content-between mb-3">
                              {post.blogCategory && (
                                <p
                                  style={{ fontSize: "0.9em" }}
                                  className="text-em m-0 blog-category fw-bold"
                                >
                                  {categoryMapping[post.blogCategory]}
                                </p>
                              )}
                              <p
                                style={{ fontSize: "0.9em" }}
                                className="text-muted m-0"
                              >
                                <span className="pe-2 text-muted">
                                  {months[new Date(post.date).getMonth()]} {""}
                                  {new Date(post.date).getDate()},{" "}
                                  {new Date(post.date).getFullYear()}
                                </span>
                              </p>
                            </div>

                            <div className="">
                              <h5
                                className="mb-4 blog-heading"
                                style={{
                                  fontFamily: "Metropolis-medium",
                                  display: "-webkit-box",
                                  maxWidth: "100%",
                                  fontSize: "1.15em",
                                  WebkitLineClamp: "3",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {post.title}
                              </h5>
                              <p
                                className="text-muted mt-2"
                                style={{
                                  display: "-webkit-box",
                                  fontSize: "0.9em",
                                  maxWidth: "100%",
                                  WebkitLineClamp: "3",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {post.excerpt}
                              </p>
                            </div>
                          </div>

                          {/* <Link
                          to={`/blogs/${post.slug}`}
                          style={{}}
                          className="background-em w-100 text-decoration-none d-block text-center border-0 rounded-3 text-light p-2 w-100"
                        >
                          Read More{" "}
                          <Icon icon="ph:arrow-right-light" width={30} />
                        </Link> */}
                        </div>
                      </Link>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};

export default BlogsScreen;
