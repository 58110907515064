import { Link } from "react-router-dom";
import "../CSS/home.css";
import "../CSS/mobHome.css";
import { Icon } from "@iconify/react";

const TestRide = () => {
  return (
    <>
      <div
        className="background-em position-fixed d-flex d-md-none align-items-center justify-content-between px-3 py-1"
        style={{
          bottom: "0%",
          zIndex: "10",
          width: "100%",
          gap: "-1.2em",
          height: "10%",
        }}
      >
        <div>
          <div className="d-flex text-light align-content-center">
            <Icon
              icon="ic:round-star-rate"
              style={{ color: "#ffce31" }}
              width={"20"}
            />
            <Icon
              icon="ic:round-star-rate"
              style={{ color: "#ffce31" }}
              width={"20"}
            />
            <Icon
              icon="ic:round-star-rate"
              style={{ color: "#ffce31" }}
              width={"20"}
            />
            <Icon
              icon="ic:round-star-rate"
              style={{ color: "#ffce31" }}
              width={"20"}
            />
            <Icon
              icon="ic:round-star-half"
              style={{ color: "#ffce31" }}
              width={"20"}
            />
          </div>
          <small
            style={{ fontSize: "0.55em" }}
            className="text-center text-light"
          >
            India’s Top-rated electric cycles
          </small>
        </div>
        <div className="d-flex justify-content-center">
          <Link
            to={"/testride"}
            style={{
              color: "inherit",
              backgroundColor: "#00ff7f",
              border: "0px solid #ffffff",
              borderRadius: "25px",
              padding: "0.5em 1em",
            }}
            className="btn btn-light fw-bold text-em ms-3"
          >
            Book Test Ride
          </Link>
        </div>
      </div>
    </>
  );
};

export default TestRide;
